<template>
    <b-card class="plafond-info-card" :title="__('current_activity')">
        <div class="d-flex flex-wrap">
            <div class="d-flex flex-column">
                <dl class="card-data-list">
                    <dt>{{ __('driver') }}</dt>
                    <dd>{{ fullName }}</dd>
                    <dt>{{ __('driver_card') }}</dt>
                    <dd>{{ cardNumer }}</dd>
                </dl>
                <PlafondDriverActivities :value="driverActivities" style="flex: 1" />
            </div>
            <div
                :class="[
                    'icon-panel',
                    `icon-panel--${driverActivities.state?.toLocaleLowerCase()}`
                ]"
            >
                <!-- Icona associata allo stato -->
                <i :class="getIconClass(driverActivities.state)" />
                <!-- Testo. Attenzione al caso di stato BLOCK, driverActivity mostra Fermati come stringa. -->
                <template v-if="driverActivities.state === 'BLOCK'">
                    <p>
                        {{ driverActivities.currentText }}
                    </p>
                </template>
                <template v-else>
                    <label>{{ __(`plafonds.driver_in_${driverActivities.state}`) }}</label>
                    <p>
                        {{ driverActivities.currentText }}
                    </p>
                </template>
            </div>
        </div>
    </b-card>
</template>

<script>
import { computed, toRefs } from 'vue'
import PlafondDriverActivities from '../../PlafondDriverActivities.vue'
import useDriverPlafond from '../../../composables/useDriverPlafond'

export default {
    name: 'CurrentActivityCard',
    components: {
        PlafondDriverActivities
    },
    props: {
        driver: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const { driver } = toRefs(props)

        const { driverActivities, getIconClass } = useDriverPlafond(driver)

        const fullName = computed(() => `${driver.value?.surname} ${driver.value?.name}`)
        const cardNumer = computed(() => driver.value?.card)

        return {
            fullName,
            cardNumer,
            driverActivities,
            getIconClass
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep(.tiny-plafond) {
    font-size: 0.7rem;
}

.icon-panel {
    margin-left: 25px;
    position: relative;
    flex: 1;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    label,
    p {
        z-index: 1;
    }
    label {
        font-size: 1rem;
    }
    p {
        font-size: 2rem;
        font-weight: bold;
    }

    i {
        position: absolute;
        color: #e7e7e7;
        font-size: 170px;
    }

    &--rest {
        color: #87cb7e;
    }
    &--available {
        color: #96cee9;
    }
    &--work {
        color: #fc9e42;
    }
    &--drive {
        color: #e83744;
    }
}
</style>
