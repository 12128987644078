<template>
    <div class="r-wrapper">
        <div class="spinner-wrapper" v-show="loading">
            <Spinner></Spinner>
        </div>
        <transition name="common-fade" @after-leave="showResponse = true">
            <div class="big-wrapper" v-if="(!routeResponse && !loading) || loadedBreaksAndRests">
                <div class="accordion" role="tablist">
                    <!-- Form Informazioni percorso -->
                    <b-card no-body class="mb-2">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.step-1 variant="default">
                                1 - {{ __('planner.fill_route_info') }}
                                <i class="fa fa-chevron-up"></i><i class="fa fa-chevron-down"></i>
                            </b-button>
                        </b-card-header>
                        <b-collapse
                            id="step-1"
                            visible
                            accordion="planner-accordion"
                            role="tabpanel"
                            v-model="plannerCollapses.form"
                        >
                            <golia-button
                                v-if="$can('use', 'planner_saved_routes')"
                                :label="__('planner.savedroutes.my_routes')"
                                :badge="savedRoutes.savedTripsPagination?.totalItems"
                                icon="fa-solid fa-heart"
                                class="my-saved-trips d-block mt-3 ms-3"
                                v-b-modal.modal-saved-trips
                            />

                            <!-- Modale percorsi salvati -->
                            <SavedRoute
                                v-model="savedRoutes"
                                @savedRoute="loadTrip"
                                @deleteSavedTrip="deleteSavedTrip"
                            />

                            <b-card-body>
                                <RouteInfo v-model="routeInfo" :profiles="profiles" />
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <!-- Panel nascosto per la configurazione del plafond -->
                    <b-card no-body class="mb-2" v-show="routeInfo.includePlafond">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.step-plafond variant="default">
                                {{ __('planner.plafond_configuration.title') }}
                                <i class="fa fa-chevron-up"></i><i class="fa fa-chevron-down"></i>
                            </b-button>
                        </b-card-header>
                        <b-collapse
                            id="step-plafond"
                            accordion="planner-accordion"
                            role="tabpanel"
                            v-model="plannerCollapses.plafond"
                        >
                            <b-card-body>
                                <div class="mb-3">
                                    <b>
                                        {{
                                            __(
                                                'planner.plafond_configuration.driving_time_regulation'
                                            )
                                        }}
                                        <i
                                            class="fa-solid fa-circle-question text-primary d-none"
                                            id="driving_time_regulation"
                                        ></i>
                                    </b>
                                    <b-popover
                                        target="driving_time_regulation"
                                        customClass="label-helper"
                                        triggers="hover"
                                        placement="top"
                                        variant="info"
                                    >
                                        {{
                                            __(
                                                'planner.plafond_configuration.driving_time_regulation'
                                            )
                                        }}
                                    </b-popover>

                                    <golia-input
                                        type="select"
                                        v-model="plafondConfig.configurableWorkingHoursPreset"
                                        :options="[
                                            {
                                                value: 'EU_DRIVING_TIME_REGULATION_FOR_SINGLE_DAY',
                                                text: __(
                                                    'planner.plafond_configuration.eu_driving_time_regulation_for_single_day'
                                                )
                                            },
                                            {
                                                value: 'EU_DRIVING_TIME_REGULATION_FOR_LONG_SINGLE_DAY',
                                                text: __(
                                                    'planner.plafond_configuration.eu_driving_time_regulation_for_single_long_day'
                                                )
                                            },
                                            {
                                                value: 'EU_DRIVING_TIME_REGULATION_FOR_MULTIPLE_DAYS',
                                                text: __(
                                                    'planner.plafond_configuration.eu_driving_time_regulation_for_multiple_day'
                                                )
                                            },
                                            {
                                                value: 'EU_DRIVING_TIME_REGULATION_FOR_TEAM_AND_MULTIPLE_DAYS',
                                                text: __(
                                                    'planner.plafond_configuration.eu_driving_time_regulation_for_team_and_multiple_day'
                                                )
                                            }
                                        ]"
                                    ></golia-input>
                                </div>

                                <div class="mb-3">
                                    <b>
                                        {{
                                            __(
                                                'planner.plafond_configuration.last_time_the_driver_worked'
                                            )
                                        }}
                                        <i
                                            class="fa-solid fa-circle-question text-primary d-none"
                                            id="last_time_the_driver_worked"
                                        ></i>
                                    </b>
                                    <b-popover
                                        target="last_time_the_driver_worked"
                                        customClass="label-helper"
                                        triggers="hover"
                                        placement="top"
                                        variant="info"
                                    >
                                        {{
                                            __(
                                                'planner.plafond_configuration.last_time_the_driver_worked'
                                            )
                                        }}
                                    </b-popover>

                                    <golia-date-picker
                                        v-model="plafondConfig.lastTimeTheDriverWorked"
                                        alignCalendar="left"
                                        :monthPanels="1"
                                        :singleDate="true"
                                        :specificTime="true"
                                    ></golia-date-picker>
                                </div>

                                <div class="mb-3">
                                    <b>
                                        {{
                                            __(
                                                'planner.plafond_configuration.accumulated_driving_time_since_lastbreak'
                                            )
                                        }}
                                        <i
                                            class="fa-solid fa-circle-question text-primary d-none"
                                            id="accumulated_driving_time_since_lastbreak"
                                        ></i>
                                    </b>
                                    <b-popover
                                        target="accumulated_driving_time_since_lastbreak"
                                        customClass="label-helper"
                                        triggers="hover"
                                        placement="top"
                                        variant="info"
                                    >
                                        {{
                                            __(
                                                'planner.plafond_configuration.accumulated_driving_time_since_lastbreak'
                                            )
                                        }}
                                    </b-popover>

                                    <golia-input
                                        v-model="plafondConfig.accumulatedDrivingTimeSinceLastBreak"
                                    ></golia-input>
                                </div>

                                <div class="mb-3">
                                    <b>
                                        {{
                                            __(
                                                'planner.plafond_configuration.accumulated_driving_time_since_lastdailyrest'
                                            )
                                        }}
                                        <i
                                            class="fa-solid fa-circle-question text-primary d-none"
                                            id="accumulated_driving_time_since_lastdailyrest"
                                        ></i>
                                    </b>
                                    <b-popover
                                        target="accumulated_driving_time_since_lastdailyrest"
                                        customClass="label-helper"
                                        triggers="hover"
                                        placement="top"
                                        variant="info"
                                    >
                                        {{
                                            __(
                                                'planner.plafond_configuration.accumulated_driving_time_since_lastdailyrest'
                                            )
                                        }}
                                    </b-popover>

                                    <golia-input
                                        v-model="
                                            plafondConfig.accumulatedDrivingTimeSinceLastDailyRest
                                        "
                                    ></golia-input>
                                </div>

                                <div class="mb-3">
                                    <b>
                                        {{
                                            __(
                                                'planner.plafond_configuration.accumulated_travel_time_since_lastdailyrest'
                                            )
                                        }}
                                        <i
                                            class="fa-solid fa-circle-question text-primary d-none"
                                            id="accumulated_travel_time_since_lastdailyrest"
                                        ></i>
                                    </b>
                                    <b-popover
                                        target="accumulated_travel_time_since_lastdailyrest"
                                        customClass="label-helper"
                                        triggers="hover"
                                        placement="top"
                                        variant="info"
                                    >
                                        {{
                                            __(
                                                'planner.plafond_configuration.accumulated_travel_time_since_lastdailyrest'
                                            )
                                        }}
                                    </b-popover>

                                    <golia-input
                                        v-model="
                                            plafondConfig.accumulatedTravelTimeSinceLastDailyRest
                                        "
                                    ></golia-input>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <!-- Panel nascosto per l'elenco delle risorse cercate -->
                    <b-card no-body class="mb-2" v-show="localResourceSearched">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.step-middle variant="default">
                                {{ __('planner.fill_resources') }}
                                <i class="fa fa-chevron-up"></i><i class="fa fa-chevron-down"></i>
                            </b-button>
                        </b-card-header>
                        <b-collapse
                            id="step-middle"
                            accordion="planner-accordion"
                            role="tabpanel"
                            v-model="localResourceSearched"
                        >
                            <b-card-body>
                                <resources-list
                                    :resources="resources"
                                    :waypoint="getUpdatingWaypoint.waypoint"
                                    @closePanel="
                                        $root.$emit('bv::toggle::collapse', 'step-middle') &&
                                            $root.$emit('bv::toggle::collapse', 'step-2')
                                    "
                                    @searchResource="searchResource"
                                    @abortSearch="abortResourceSearch"
                                    @focusResource="focusResource"
                                    @selectResource="selectResource"
                                >
                                </resources-list>
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <!-- Panel per il form calcola percorso / isocrona -->
                    <b-card no-body class="mb-2">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.step-2 variant="default">
                                2 - {{ __('planner.fill_waypoints') }}
                                <i class="fa fa-chevron-up"></i><i class="fa fa-chevron-down"></i>
                            </b-button>
                        </b-card-header>

                        <b-collapse
                            id="step-2"
                            accordion="planner-accordion"
                            role="tabpanel"
                            v-model="plannerCollapses.waypoints"
                        >
                            <b-card-body>
                                <div class="d-flex justify-content-between">
                                    <span class="section-text">{{
                                        __('planner.waypoints_label')
                                    }}</span>
                                    <div>
                                        <golia-input
                                            v-model="calcType"
                                            type="select"
                                            class="choose-type"
                                            :class="{
                                                'disabled-select':
                                                    !waypointsCheck && waypoints.length > 1
                                            }"
                                            :disabled="!waypointsCheck && waypoints.length > 1"
                                            v-b-tooltip.hover
                                            :title="
                                                !waypointsCheck && waypoints.length > 1
                                                    ? __('planner.waypoints_check')
                                                    : ''
                                            "
                                            :input-style="{
                                                color: '#fff',
                                                'background-color': '#5C8DC8'
                                            }"
                                            :options="[
                                                {
                                                    value: 'route',
                                                    text: __('planner.calculate_route')
                                                },
                                                {
                                                    value: 'reachable_areas',
                                                    text: __('planner.isocrona')
                                                }
                                            ]"
                                        ></golia-input>
                                    </div>
                                    <div
                                        @click="addWaypointHandler"
                                        :class="{
                                            'add-waypoint': true,
                                            disabled: calcType != 'route' || !waypointsCheck
                                        }"
                                    >
                                        <i
                                            class="fa-solid fa-plus"
                                            v-b-tooltip.hover.left
                                            :title="
                                                calcType != 'route' || !waypointsCheck
                                                    ? __('planner.waypoints_check')
                                                    : ''
                                            "
                                        ></i>
                                    </div>
                                </div>

                                <div>
                                    <div v-if="calcType == 'reachable_areas'">
                                        <div class="waypoint-wrapper">
                                            <div class="wrapper-waypoint-location">
                                                <v-select
                                                    class="waypoint-location"
                                                    :filterable="false"
                                                    :options="tmpAddressesIsocrona"
                                                    :placeholder="__('planner.search_location')"
                                                    :id="waypoints.length - 1"
                                                    v-model="isocronaReference"
                                                    @search="
                                                        (search, loading) => {
                                                            searchAddress(search, loading, {})
                                                        }
                                                    "
                                                    @input="
                                                        (option) => {
                                                            optionSelectHandler(
                                                                option,
                                                                waypoints[waypoints.length - 1]
                                                            )
                                                        }
                                                    "
                                                >
                                                    <template v-slot:option="option">
                                                        <i :class="option.iconLabel"></i>
                                                        {{ option.label }}
                                                    </template>
                                                    <template slot="no-options">
                                                        {{ __('planner.search_location') }}
                                                    </template>
                                                </v-select>
                                            </div>

                                            <!-- Search info 'reachable_areas' -->
                                            <div class="mt-3" v-if="calcType == 'reachable_areas'">
                                                <div class="row mb-2" style="padding: 0px 8px">
                                                    <div class="col-lg-6">
                                                        <div class="form-check mb-2">
                                                            <input
                                                                :id="waypoints.length + 'fuel-type'"
                                                                class="form-check-input"
                                                                type="checkbox"
                                                                value="stations"
                                                                v-model="isocronaSearchInfo"
                                                            />
                                                            <label
                                                                :for="
                                                                    waypoints.length + 'fuel-type'
                                                                "
                                                                class="form-check-label"
                                                                ><i
                                                                    class="fa-solid fa-gas-pump"
                                                                ></i>
                                                                {{
                                                                    __('planner.search_station')
                                                                }}</label
                                                            >
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-check">
                                                            <input
                                                                :id="
                                                                    waypoints.length +
                                                                    'parking-type'
                                                                "
                                                                class="form-check-input"
                                                                type="checkbox"
                                                                value="parkings"
                                                                v-model="isocronaSearchInfo"
                                                            />
                                                            <label
                                                                :for="
                                                                    waypoints.length +
                                                                    'parking-type'
                                                                "
                                                                class="form-check-label"
                                                                ><i
                                                                    class="fa-solid fa-square-parking"
                                                                ></i>
                                                                {{
                                                                    __('planner.search_parking')
                                                                }}</label
                                                            >
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-check mb-2">
                                                            <input
                                                                :id="waypoints.length + 'poi-type'"
                                                                class="form-check-input"
                                                                type="checkbox"
                                                                value="poi"
                                                                v-model="isocronaSearchInfo"
                                                            />
                                                            <label
                                                                :for="waypoints.length + 'poi-type'"
                                                                class="form-check-label"
                                                                ><i
                                                                    class="fa-solid fa-building"
                                                                ></i>
                                                                {{
                                                                    __('planner.search_poi')
                                                                }}</label
                                                            >
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-lg-6">
                                                        <div class="form-check mb-2">
                                                            <input :id="waypoints.length + 'borsa-type'" class="form-check-input" type="checkbox" value="borsa" v-model="isocronaSearchInfo">
                                                            <label :for="waypoints.length + 'borsa-type'" class="form-check-label"><i class="fa-solid fa-building"></i> {{ __('Cerca Borse carico') }}</label>
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </div>

                                            <div class="waypoint-wrapper aree-wrapper">
                                                <hr style="margin-top: 0px" />
                                                <div class="d-flex justify-content-between mb-2">
                                                    <span class="section-text">{{
                                                        __('areas')
                                                    }}</span>
                                                    <div
                                                        @click="reachableAreas.push({})"
                                                        :class="{ 'add-waypoint': true }"
                                                    >
                                                        <i class="fa-solid fa-plus"></i>
                                                    </div>
                                                </div>
                                                <div
                                                    v-for="(area, area_index) in reachableAreas"
                                                    :key="area_index"
                                                    class="row"
                                                >
                                                    <div
                                                        class="col-2 d-flex justify-content-center area-text"
                                                    >
                                                        <div class="choose-type">
                                                            {{ area_index + 1 }}
                                                        </div>
                                                    </div>

                                                    <div
                                                        :class="
                                                            area_index == 0 ? 'col-10' : 'col-8'
                                                        "
                                                    >
                                                        <golia-input
                                                            class="choose-type"
                                                            :input-style="{
                                                                'background-color': '#fff'
                                                            }"
                                                            v-model="area.timing"
                                                            type="number"
                                                            :additionalAttributes="{ min: 1 }"
                                                            :placeholder="__('time')"
                                                        ></golia-input>
                                                    </div>
                                                    <div
                                                        class="col-2 d-flex justify-content-center area-text"
                                                        v-if="area_index != 0"
                                                    >
                                                        <div class="delete-waypoint">
                                                            <i
                                                                @click="removeArea(area_index)"
                                                                class="fa-solid fa-trash-can"
                                                                v-b-tooltip.hover
                                                                :title="
                                                                    __('planner.delete_waypoint')
                                                                "
                                                            ></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <golia-button
                                                style="margin: 10px auto"
                                                v-if="value.polygons"
                                                @click="resetIsocrona"
                                                variant="btn-fill dark-blue btn-block w-100"
                                                :label="__('planner.reset_isocrona')"
                                                :icon="'fa-solid fa-arrow-rotate-left'"
                                            />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <draggable
                                            v-model="waypoints"
                                            group="draggable-waypoint"
                                            @start="drag = true"
                                            @end="draggedWaypoints"
                                        >
                                            <div
                                                class="waypoint-wrapper"
                                                :class="
                                                    waypoint.updatingWaypoint
                                                        ? 'updating-waypoint'
                                                        : ''
                                                "
                                                :style="
                                                    (waypoint.coordinate || {}).action ==
                                                    'searchResource'
                                                        ? 'padding-top: 15px'
                                                        : ''
                                                "
                                                v-for="(waypoint, index) in selectedWaypoints"
                                                :key="index"
                                            >
                                                <div class="wrapper-waypoint-location">
                                                    <div
                                                        class="drag-waypoint"
                                                        v-if="waypoints.length > 1"
                                                    >
                                                        <span class="fa-stack">
                                                            <i
                                                                class="fa-regular fa-stack-1x fa-bars"
                                                            ></i>
                                                            <!-- <i class="fa-regular fa-stack-1x fa-slash-back" v-if="!waypointsCheck"></i> -->
                                                        </span>
                                                    </div>

                                                    <v-select
                                                        class="waypoint-location"
                                                        :filterable="false"
                                                        :options="tmpAddressesComputed"
                                                        :placeholder="__('planner.search_location')"
                                                        :id="index"
                                                        :value="waypoint.coordinate"
                                                        @search="
                                                            (search, loading) => {
                                                                searchAddress(
                                                                    search,
                                                                    loading,
                                                                    waypoint
                                                                )
                                                            }
                                                        "
                                                        @input="
                                                            (option) => {
                                                                optionSelectHandler(
                                                                    option,
                                                                    waypoint.id
                                                                        ? waypoint
                                                                        : index + 1
                                                                )
                                                            }
                                                        "
                                                    >
                                                        <template v-slot:option="option">
                                                            <i :class="option.iconLabel"></i>
                                                            {{ option.label }}
                                                        </template>
                                                        <template slot="no-options">
                                                            {{ __('planner.search_location') }}
                                                        </template>
                                                    </v-select>

                                                    <div
                                                        class="options-waypoint d-none"
                                                        v-if="waypoint.coordinate"
                                                        @click="showFormAction(index)"
                                                    >
                                                        <i
                                                            class="fa-solid fa-lg fa-square-sliders-vertical text-success"
                                                            v-if="
                                                                (waypoint.coordinate || {}).showForm
                                                            "
                                                        ></i>
                                                        <i
                                                            class="fa-regular fa-lg fa-square-sliders-vertical"
                                                            v-else
                                                        ></i>
                                                    </div>

                                                    <div
                                                        v-if="index != 0"
                                                        class="delete-waypoint"
                                                        @click="
                                                            removeStoreWaypoint(
                                                                waypoint.id ? waypoint : index
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="fa-solid fa-trash-can"
                                                            v-b-tooltip.hover
                                                            :title="__('planner.delete_waypoint')"
                                                        ></i>
                                                    </div>
                                                </div>

                                                <!-- Waypoint options form -->
                                                <div
                                                    class="waypoint-options-form mt-2 w-100"
                                                    v-if="
                                                        (waypoints[index].coordinate || {})
                                                            .showForm === true
                                                    "
                                                >
                                                    <hr />
                                                    <div class="form-group">
                                                        <label for=""
                                                            >{{ __('service_time') }} ({{
                                                                __('minutes')
                                                            }})</label
                                                        >

                                                        <golia-input
                                                            v-model="
                                                                (waypoint.coordinate.options || {})
                                                                    .serviceTime
                                                            "
                                                        ></golia-input>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for=""
                                                            >{{ __('waypoint_load') }} ({{
                                                                __('Kg')
                                                            }})</label
                                                        >

                                                        <golia-input
                                                            v-model="
                                                                (waypoint.coordinate.options || {})
                                                                    .waypointLoad
                                                            "
                                                        ></golia-input>
                                                    </div>
                                                </div>

                                                <!-- Search info 'route' -->
                                                <resources-filters
                                                    v-if="waypoint.showAreaField"
                                                    :waypoint="waypoint"
                                                    :position="index"
                                                    @searchResource="searchResource"
                                                />

                                                <!-- Isocrona Info -->
                                                <div
                                                    v-if="
                                                        calcType == 'reachable_areas' && index == 0
                                                    "
                                                    class="waypoint-wrapper"
                                                    style="margin-top: 0px"
                                                >
                                                    >
                                                    <div
                                                        class="d-flex justify-content-between mb-2"
                                                    >
                                                        <span class="section-text">{{
                                                            __('areas')
                                                        }}</span>
                                                        <div
                                                            @click="reachableAreas.push({})"
                                                            :class="{ 'add-waypoint': true }"
                                                        >
                                                            <i class="fa-solid fa-plus"></i>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-for="(area, area_index) in reachableAreas"
                                                        :key="area_index"
                                                        class="row"
                                                    >
                                                        <div
                                                            class="col-2 d-flex justify-content-center area-text"
                                                        >
                                                            <div class="choose-type">
                                                                {{ area_index + 1 }}
                                                            </div>
                                                        </div>

                                                        <div
                                                            :class="
                                                                area_index == 0 ? 'col-10' : 'col-8'
                                                            "
                                                        >
                                                            <golia-input
                                                                class="choose-type"
                                                                :input-style="{
                                                                    'background-color': '#fff'
                                                                }"
                                                                v-model="area.timing"
                                                                type="number"
                                                                :placeholder="__('time')"
                                                            ></golia-input>
                                                        </div>
                                                        <div
                                                            class="col-2 d-flex justify-content-center area-text"
                                                            v-if="area_index != 0"
                                                        >
                                                            <div class="delete-waypoint">
                                                                <i
                                                                    @click="removeArea(area_index)"
                                                                    class="fa-solid fa-trash-can"
                                                                    v-b-tooltip.hover
                                                                    :title="
                                                                        __(
                                                                            'planner.delete_waypoint'
                                                                        )
                                                                    "
                                                                ></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </draggable>
                                    </div>

                                    <div
                                        class="d-flex justify-content-between"
                                        v-if="calcType == 'route' && waypoints.length < 2"
                                    >
                                        <span class="section-text">{{
                                            __('planner.waypoints_required')
                                        }}</span>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>

                <golia-button
                    style="padding-top: 8px; padding-bottom: 8px"
                    @click="setActionButton"
                    variant="btn-fill dark-blue-1 btn-block w-100"
                    :label="actionButtonText"
                    :icon="actionButtonIcon"
                />

                <golia-button
                    style="padding-top: 8px; padding-bottom: 8px"
                    @click="loadBreaksAndRests"
                    variant="btn-fill dark-blue btn-block w-100 mt-2 d-none"
                    :label="__('planner.load_breaks')"
                    v-if="
                        routeInfo.includePlafond &&
                        waypointsCheck &&
                        this.waypoints.length > 1 &&
                        !loadedBreaksAndRests
                    "
                />

                <!-- Nuovo pulsante "Vedi alternative" -->
                <golia-button
                    style="padding-top: 8px; padding-bottom: 8px"
                    @click="loadAlternativeRoutes"
                    variant="btn-fill dark-blue btn-block w-100 mt-2 d-none"
                    :label="__('planner.load_alternative_routes')"
                    icon="fa-solid fa-code-fork"
                    v-if="waypointsCheck && this.waypoints.length > 1 && !loadedBreaksAndRests"
                />
            </div>
        </transition>

        <transition name="common-fade" @after-leave="showResponse = false">
            <div
                v-if="showResponse && routeResponse && !loadedBreaksAndRests"
                class="d-flex flex-column route-response"
            >
                <div class="response-header">
                    <div class="response-route-buttons">
                        <golia-button
                            :label="__('planner.reset_route')"
                            icon="fa-solid fa-route"
                            variant="btn-fill dark-blue btn-block w-100"
                            @click="() => resetRoute(null)"
                        />
                        <golia-button
                            :label="routeResponse.message ? __('planner.edit_route') : null"
                            icon="fa-solid fa-fw fa-pencil"
                            :variant="
                                routeResponse.message
                                    ? 'btn-info btn-block w-100'
                                    : 'btn-info btn-circle'
                            "
                            @click="editRoute"
                            v-b-tooltip.hover
                            :title="__('planner.edit_route')"
                        />
                        <golia-button
                            icon="fa-solid fa-fw fa-save"
                            variant="btn-success btn-circle"
                            v-b-modal.modal-save-route
                            v-b-tooltip.hover
                            :title="__('planner.save_route')"
                            v-if="!routeResponse.message"
                        />

                        <!-- Modale salva viaggio -->
                        <b-modal
                            id="modal-save-route"
                            ref="saveRouteModal"
                            :title="__('planner.save_route')"
                            size="lg"
                        >
                            <div class="d-flex flex-column">
                                <div class="d-flex flex-column">
                                    <golia-input
                                        v-model="savedRouteName"
                                        :placeholder="__('planner.route_name')"
                                        :input-style="{ 'background-color': '#fff' }"
                                    ></golia-input>
                                </div>
                            </div>
                            <template v-slot:modal-footer="{ cancel }">
                                <golia-button
                                    variant="btn-fill dark-blue"
                                    @click="cancel()"
                                    :label="__('cancel')"
                                />
                                <golia-button
                                    :label="__('planner.override_route')"
                                    icon="fa-solid fa-pencil"
                                    variant="btn-info"
                                    @click="saveRoute(true)"
                                    v-if="savedRoutes.selectedTrip"
                                />
                                <golia-button
                                    :label="
                                        savedRoutes.selectedTrip
                                            ? __('planner.save_as_new_route')
                                            : __('save')
                                    "
                                    icon="fa-solid fa-save"
                                    variant="btn-success"
                                    @click="() => saveRoute(false)"
                                />
                            </template>
                        </b-modal>

                        <b-dropdown
                            id="calculated-route-options"
                            no-caret
                            variant="fill gray btn-circle"
                            v-b-tooltip.hover
                            :title="__('options')"
                            menu-class="calculated-route-options"
                            v-if="!routeResponse.message"
                        >
                            <template #button-content>
                                <i class="fa-solid fa-fw fa-ellipsis-vertical"></i>
                            </template>
                            <b-dropdown-item @click="exportPTVNavigator">{{
                                __('planner.ptvexport')
                            }}</b-dropdown-item>
                        </b-dropdown>
                    </div>

                    <!-- Errore percorso -->
                    <b-alert variant="danger" class="mt-3" show v-if="routeResponse.message">
                        <b>{{ __('planner.route_error') }}</b
                        ><br /><br />
                        {{ routeResponse.message }}
                    </b-alert>

                    <!-- Plafond violato -->
                    <b-alert
                        variant="warning"
                        class="mt-3 p-2"
                        show
                        v-if="
                            routeResponse.violated &&
                            routeInfo.includePlafond &&
                            plafondConfig.configurableWorkingHoursPreset ==
                                'EU_DRIVING_TIME_REGULATION_FOR_SINGLE_DAY'
                        "
                    >
                        <b>{{ __('planner.violated_plafond') }}</b>
                    </b-alert>

                    <div class="recap" v-if="!routeResponse.message">
                        <table>
                            <tr>
                                <td class="col-8">
                                    <b>{{ __('distance_total') }}</b>
                                </td>
                                <td>{{ routeResponse.distance | metersToKm }}</td>
                            </tr>
                            <tr>
                                <td class="col-8">
                                    <b>{{ __('duration') }}</b>
                                </td>
                                <td
                                    :class="
                                        routeResponse.trafficDelay > 30 * 60 ? 'text-danger' : ''
                                    "
                                >
                                    {{ routeResponse.travelTime | secondsToTime({ s: false }) }}
                                    <span v-if="routeResponse.trafficDelay > 900"
                                        >( +{{
                                            routeResponse.trafficDelay | secondsToTime({ s: false })
                                        }}
                                        <i class="fa-solid fa-cars"></i> )
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="col-8">
                                    <b>{{ __('toll_cost') }}</b>
                                </td>
                                <td>{{ routeResponse.monetaryCostsReport.tollCost }} €</td>
                            </tr>
                            <tr>
                                <td class="col-8">
                                    <b>{{ __('km_cost') }}</b
                                    ><br /><span class="choose-type"
                                        >({{ routeInfo.costPerKilometer || 1.2 }} €/Km)</span
                                    >
                                </td>
                                <td>{{ routeResponse.monetaryCostsReport.distanceCost }} €</td>
                            </tr>
                            <tr>
                                <td class="col-8">
                                    <b>{{ __('working_cost') }}</b
                                    ><br /><span class="choose-type"
                                        >({{ routeInfo.workingCostPerHour || 20.5 }} €/h)</span
                                    >
                                </td>
                                <td>{{ routeResponse.monetaryCostsReport.workingTimeCost }} €</td>
                            </tr>
                            <tr>
                                <td class="col-8">
                                    <b>{{ __('total_cost') }}</b>
                                </td>
                                <td>
                                    {{
                                        (
                                            routeResponse.monetaryCostsReport.tollCost +
                                            routeResponse.monetaryCostsReport.distanceCost +
                                            routeResponse.monetaryCostsReport.workingTimeCost
                                        ).toFixed(2)
                                    }}
                                    €
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="response-body" v-if="!routeResponse.message">
                    <event-viewer
                        :waypoints="waypoints"
                        :events="routeResponse.events"
                        :emissions="(routeResponse.emissions || {}).values"
                        :legs="routeResponse.legs"
                        :toll="routeResponse.toll"
                    />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import EventViewer from './EventViewer.vue'
import Spinner from 'vue-simple-spinner'
import draggable from 'vuedraggable'
import RouteInfo from './RoutePlanner/RouteInfo.vue'
import SavedRoute from './RoutePlanner/SavedRoutes.vue'
import { saveAs } from 'file-saver'

import { mapState, mapGetters, mapMutations } from 'vuex'

const time_format = require('format-duration')

export default {
    name: 'RoutePlanner',
    props: {
        clickedCoordinates: {
            type: Array,
            required: false
        },
        resourceSearched: {
            type: Boolean,
            required: false
        },
        resources: {
            type: Array,
            required: false
        },
        selectedWaypoints: {
            type: Array,
            required: false
        },
        errorsInWaypoints: {
            type: Boolean,
            required: false
        },
        truck: {
            type: Object,
            required: false,
            default: () => ({ lastpoint: null, profile: null, category: null })
        },
        value: {
            type: Object,
            required: false
        }
    },
    components: {
        EventViewer,
        Spinner,
        draggable,
        RouteInfo,
        SavedRoute
    },
    data() {
        return {
            minOpacity: 0.4,
            profiles: [],
            routeInfo: {
                chosenProfile: null,
                selectedCo2Class: null,
                startMode: 'start',
                startTime: { start: this.moment(), end: this.moment().endOf('day') },
                costPerKilometer: null,
                workingCostPerHour: null,
                includePlafond: false,

                tollPenalty: 405,
                tollPenaltyMarks: {
                    '-95': {
                        label: this.__('exclude'),
                        labelStyle: {
                            left: '-5px',
                            transform: 'translateX(0)'
                        }
                    },
                    500: {
                        label: this.__('include'),
                        labelStyle: {
                            left: 'initial',
                            right: '-5px',
                            transform: 'translateX(0)'
                        }
                    }
                },
                tollPenaltyDots: {
                    tooltip: 'none',
                    style: {
                        backgroundColor: 'var(--dark-blue)'
                    },
                    focusStyle: {
                        backgroundColor: 'var(--dark-blue)'
                    }
                },
                sliderOptions: {
                    tooltip: 'none',
                    style: {
                        backgroundColor: 'var(--dark-blue)'
                    },
                    focusStyle: {
                        backgroundColor: 'var(--dark-blue)'
                    },
                    tooltipStyle: {
                        backgroundColor: 'var(--dark-blue)'
                    },
                    tooltipFocusStyle: {
                        backgroundColor: 'var(--dark-blue)'
                    }
                },
                distanceTimeWeighting: 50,
                distanceTimeWeightingMarks: {
                    0: {
                        label: this.__('distance'),
                        labelStyle: {
                            left: '-5px',
                            transform: 'translateX(0)',
                            opacity: 1
                        }
                    },
                    100: {
                        label: this.__('time_label'),
                        labelStyle: {
                            left: 'initial',
                            right: '-5px',
                            transform: 'translateX(0)',
                            opacity: 1
                        }
                    }
                }
            },
            savedRoutes: {
                savedTripsLoading: false,
                savedTrips: [],
                selectedTrip: null,
                savedTripsPagination: {
                    currentPage: 1,
                    perPage: 10,
                    totalItems: 0
                }
            },
            plannerCollapses: {
                form: true,
                plafond: false,
                waypoints: false
            },
            localResourceSearched: false,
            selectOnMap: null,
            tmpAddressesIsocrona: [{}],
            isocronaReference: null,
            isocronaSearchInfo: [],

            calcType: 'route',
            avoidToll: false,

            shorterWay: false,

            delivery_nations: ['Italia', 'Francia', 'Germania', 'Spagna', 'Regno Unito'],
            vehicle_types: [
                { label: 'Bilico', value: 'TRAILER' },
                { label: 'Veicolo superiore alle 3.5 t', value: 'VEHICLE_UP_TO_3_5_T' },
                { label: 'Veicolo superiore alle 12 t', value: 'VEHICLE_UP_TO_12_T' },
                { label: 'Veicolo superiore alle 7.5 t', value: 'VEHICLE_UP_TO_7_5_T' },
                { label: 'Camion rimorchio', value: 'WAGGON_AND_DRAG' }
            ],
            vehicle_sizes: [
                { label: 'Cassonato', value: 'BOX' },
                { label: 'Automobile', value: 'CAR' },
                { label: 'Bisarca', value: 'CAR_TRANSPORTER' },
                { label: 'Container', value: 'CONTAINER' },
                { label: 'Cisterna', value: 'TANK' }
            ],

            plafondConfig: {
                configurableWorkingHoursPreset: 'EU_DRIVING_TIME_REGULATION_FOR_SINGLE_DAY',
                lastTimeTheDriverWorked: {
                    start: this.moment().startOf('day'),
                    end: this.moment().endOf('day')
                }, // Timestamp
                accumulatedDrivingTimeSinceLastBreak: 0, // session.done
                accumulatedDrivingTimeSinceLastDailyRest: 0, // day.drive
                accumulatedTravelTimeSinceLastDailyRest: 0 // day.sum(all props - start)
            },

            routeResponse: null,
            loadedBreaksAndRests: false,
            showResponse: false,
            waypoints: this.selectedWaypoints || [{}],
            reachableAreas: [{}],

            loading: false,
            drag: false,

            gmaps: null,
            locationSearchResults: [],

            savedRouteName: null
        }
    },
    metaInfo() {
        return {
            script: [
                {
                    src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyDHi20jpKVCTkdIfgWXUQFk56x4FEBh6D4&libraries=places`,
                    async: true,
                    defer: true,
                    callback: () => this.GoogleMapsInit()
                }
            ]
        }
    },
    created() {
        this.setUp()
        this.loadProfiles()

        this.calculateTimeDistanceOpacity()
        this.calculateBgGradient()
    },
    mounted() {
        if (!this.gmaps) {
            this.GoogleMapsInit()
        }
    },
    computed: {
        waypointsCheck() {
            return !this.errorsInWaypoints
        },
        breaksInWaypoints() {
            return {
                unelaborated: this.waypoints.filter(
                    (w) =>
                        w.shadowWaypoint &&
                        (w.coordinate.action == 'break' || w.coordinate.action == 'rest')
                ),
                elaborated: this.waypoints.filter(
                    (w) =>
                        w.shadowWaypoint &&
                        w.coordinate.action != 'break' &&
                        w.coordinate.action != 'rest'
                )
            }
        },
        actionButtonText() {
            if (this.calcType == 'route') {
                if (this.waypoints.length > 1 && this.waypointsCheck) {
                    return this.__('planner.calculate_route')
                } else {
                    return this.__('planner.complete_step_2')
                }
            } else {
                if (
                    this.isocronaReference &&
                    Object.prototype.hasOwnProperty.call(this.reachableAreas[0], 'timing') &&
                    this.reachableAreas[0].timing != ''
                ) {
                    return this.__('planner.calculate_isocrona')
                } else {
                    return this.__('planner.complete_step_2')
                }
            }
        },
        actionButtonIcon() {
            if (this.calcType == 'route') {
                if (this.waypoints.length > 1 && this.waypointsCheck) {
                    return 'fa-solid fa-route'
                } else {
                    return 'fa-solid fa-arrow-turn-up'
                }
            } else {
                if (
                    this.isocronaReference &&
                    Object.prototype.hasOwnProperty.call(this.reachableAreas[0], 'timing') &&
                    this.reachableAreas[0].timing != ''
                ) {
                    return 'fa-solid fa-vector-circle'
                } else {
                    return 'fa-solid fa-arrow-turn-up'
                }
            }
        },
        truckPresence() {
            return this.truck.lastpoint != null
        },
        driverPresence() {
            return this.truckPresence ? this.truck.lastpoint.driver != null : false
        },
        coDriverPresence() {
            return this.truckPresence ? this.truck.lastpoint.co_driver != null : false
        },
        tmpAddressesComputed: {
            get: function () {
                var start_position = this.truckPresence
                    ? { x: this.truck.lastpoint.lng, y: this.truck.lastpoint.lat }
                    : { x: 0, y: 0 }

                var returningOptions = []

                // Se è presente il veicolo selezionato aggiungo come possibilità di partenza la posizione attuale del veicolo
                if (this.truckPresence) {
                    returningOptions.push({
                        referenceCoordinate: start_position,
                        iconLabel:
                            this.truck.category && this.truck.category.fa
                                ? JSON.parse(this.truck.category.fa).icon
                                : 'fak fa-fw fa-trattore-stradale',
                        label: this.__('planner.vehicle_actual_position')
                    })
                }

                // Seleziona su mappa è sempre disponibile
                returningOptions.push({
                    referenceCoordinate: start_position,
                    iconLabel: 'fa-solid fa-fw fa-crosshairs',
                    action: 'selectOnMap',
                    label: this.__('planner.select_on_map_option')
                })

                // La ricerca delle risorse è disponibile solo se è stato selezionato almeno un waypoint, nel caso in cui non sia presente il veicolo selezionato
                let permissionToSearch = true

                if (
                    !this.waypoints[0].coordinate &&
                    !(this.waypoints[0].coordinate || {}).updatingWaypoint
                ) {
                    permissionToSearch = false
                }

                if (this.waypoints.length == 1) permissionToSearch = false

                if (this.truckPresence) permissionToSearch = true

                if (permissionToSearch) {
                    if (this.$can('use', 'fuel_stations'))
                        returningOptions.push({
                            referenceCoordinate: start_position,
                            resourceType: 'fuel',
                            action: 'searchResource',
                            iconLabel: 'fa-solid fa-fw fa-gas-pump',
                            label: this.__('planner.search_station')
                        })

                    if (this.$can('use', 'parkings'))
                        returningOptions.push({
                            referenceCoordinate: start_position,
                            resourceType: 'parking',
                            action: 'searchResource',
                            iconLabel: 'fa-solid fa-fw fa-square-parking',
                            label: this.__('planner.search_parking')
                        })

                    returningOptions.push({
                        referenceCoordinate: start_position,
                        resourceType: 'poi',
                        action: 'searchResource',
                        iconLabel: 'fa-solid fa-fw fa-building',
                        label: this.__('planner.search_poi')
                    })

                    if (
                        this.$store.state.user.payload &&
                        this.$store.state.user.payload.admin.helpdesk
                    ) {
                        if (this.userHasFreightPermission) {
                            if (this.storeUser.loggedToAlpega)
                                returningOptions.push({
                                    referenceCoordinate: start_position,
                                    resourceType: 'borsa',
                                    action: 'searchResource',
                                    iconLabel: 'fak fa-fw fa-alpega',
                                    label: this.__('planner.alpega')
                                })

                            if (this.storeUser.loggedToTimocom)
                                returningOptions.push({
                                    referenceCoordinate: start_position,
                                    resourceType: 'borsa-timocom',
                                    action: 'searchResource',
                                    iconLabel: 'fak fa-fw fa-timocom',
                                    label: this.__('planner.timocom')
                                })
                        }
                    }
                }

                if (this.locationSearchResults.length > 0) {
                    returningOptions = this.locationSearchResults
                }

                return returningOptions
            }
        },
        ...mapState({
            driversData: (state) => state.geoFleet.driversData,
            storeWaypoints: (state) => state.planner.waypoints,
            storeUser: (state) => state.user
        }),
        ...mapGetters({
            getUpdatingWaypoint: 'getUpdatingWaypoint',
            userHasFreightPermission: 'hasFreightPermission'
        })
    },
    watch: {
        'routeInfo.includePlafond'(val) {
            this.plannerCollapses.plafond = val
        },
        resourceSearched() {
            this.localResourceSearched = this.resourceSearched

            if (this.resourceSearched) {
                this.waypoints.forEach(
                    function (item) {
                        if (item.showAreaField) {
                            item.showAreaField = false
                        }
                    }.bind(this)
                )
            }
        },
        calcType() {
            this.resetSelectOptions()
        },
        truck() {
            this.setUp()
        },
        selectedWaypoints: {
            handler: function (newVal) {
                this.waypoints = newVal
            },
            deep: true
        },
        async clickedCoordinates(newV) {
            var infoIndex = null

            var clickedAddress = await this.$reverseGeocoding({
                lat: newV[0],
                lng: newV[1],
                full: true
            })

            if (this.calcType == 'route') {
                let waypoint = this.getUpdatingWaypoint.waypoint

                waypoint.coordinate.referenceCoordinate = { x: newV[1], y: newV[0] }
                waypoint.coordinate.label = clickedAddress
                waypoint.updatingWaypoint = false

                this.$store.commit('updateWaypoint', { waypoint: waypoint })

                this.$emit('resetResource')
            } else {
                infoIndex = this.tmpAddressesIsocrona.findIndex((element) => {
                    return element.action == 'selectOnMap'
                })

                this.tmpAddressesIsocrona[infoIndex].address = clickedAddress
                this.tmpAddressesIsocrona[infoIndex].referenceCoordinate.x = newV[1]
                this.tmpAddressesIsocrona[infoIndex].referenceCoordinate.y = newV[0]
                this.tmpAddressesIsocrona[infoIndex].label = clickedAddress

                var tmpWaypoint = {
                    coordinate: {
                        action: 'selectOnMap',
                        referenceCoordinate: {
                            x: newV[1],
                            y: newV[0]
                        },
                        label: clickedAddress,
                        fromIsocrona: true
                    }
                }

                this.$emit('placeMarker', tmpWaypoint)
                this.resetSelectOptions()
            }
        },
        'savedRoutes.savedTripsPagination': {
            handler: function () {
                this.loadSavedTrips()
            },
            deep: true
        },
        'routeInfo.distanceTimeWeighting'() {
            this.calculateTimeDistanceOpacity()
        },
        'routeInfo.tollPenalty'() {
            this.calculateBgGradient()
        }
    },
    methods: {
        time_format: time_format,
        calculateTimeDistanceOpacity() {
            let value = this.routeInfo.distanceTimeWeighting // 0 - 100

            // Opacity must be relative to the value and between this.minOpacity and 1
            let opacity = (value * (1 - this.minOpacity)) / 100

            this.routeInfo.distanceTimeWeightingMarks[0].labelStyle.opacity = 1 - opacity
            this.routeInfo.distanceTimeWeightingMarks[100].labelStyle.opacity =
                this.minOpacity + opacity
        },
        calculateBgGradient() {
            let value = this.routeInfo.tollPenalty // -95 - 500

            // Gradient must be relative to the value and between this.minOpacity and 1
            let gradient = (value * (1 - this.minOpacity)) / 500

            this.routeInfo.tollPenaltyDots.style.backgroundColor = `rgba(var(--dark-blue--rgb), ${
                this.minOpacity + gradient
            })`
            this.routeInfo.tollPenaltyDots.focusStyle.backgroundColor = `rgba(var(--dark-blue--rgb), ${
                this.minOpacity + gradient
            })`
        },
        GoogleMapsInit() {
            this.gmaps = ((window.google || {}).maps || {}).places
        },
        setUp() {
            let co2Parameter = parseInt(_get(this.truck, 'parameters.9.valore', null))

            this.routeInfo.chosenProfile = this.truck.profile ? this.truck.profile.id : null
            this.routeInfo.selectedCo2Class = co2Parameter
            this.loadSavedTrips()
            this.resetSelectOptions()
            this.setDriverPlafond()
        },
        setDriverPlafond() {
            if (this.driversData && Object.keys(this.driversData).length > 0) {
                let driver = this.driverPresence
                    ? this.driversData[this.truck.lastpoint.driver.id]
                    : null
                let codriver = this.codriverPresence
                    ? this.driversData[this.truck.lastpoint.codriver.id]
                    : null

                if (driver) {
                    this.plafondConfig.lastTimeTheDriverWorked.start = this.moment(
                        driver.timestamp,
                        'UTC'
                    )
                    this.plafondConfig.accumulatedDrivingTimeSinceLastBreak = (
                        driver.session.done /
                        60 /
                        60
                    ).toFixed(2)
                    this.plafondConfig.accumulatedDrivingTimeSinceLastDailyRest = (
                        driver.day.drive /
                        60 /
                        60
                    ).toFixed(2)
                    this.plafondConfig.accumulatedTravelTimeSinceLastDailyRest = (
                        (driver.day.availability +
                            driver.day.drive +
                            driver.day.rest +
                            driver.day.work) /
                        60 /
                        60
                    ).toFixed(2)
                }

                if (codriver) {
                    // For this.plafondConfig.lastTimeTheDriverWorked.start find the latest timestamp between driver and codriver using moment.js
                    this.plafondConfig.lastTimeTheDriverWorked.start = this.moment.max(
                        this.moment(driver.timestamp, 'UTC'),
                        this.moment(codriver.timestamp, 'UTC')
                    )
                    this.plafondConfig.accumulatedDrivingTimeSinceLastBreak +=
                        codriver.session.done.toFixed(2)
                    this.plafondConfig.accumulatedDrivingTimeSinceLastDailyRest +=
                        codriver.day.drive.toFixed(2)
                    this.plafondConfig.accumulatedTravelTimeSinceLastDailyRest += (
                        codriver.day.availability +
                        codriver.day.drive +
                        codriver.day.rest +
                        codriver.day.work
                    ).toFixed(2)
                }
            }
        },
        resetSelectOptions() {
            var start_position = this.truck.lastpoint
                ? { x: this.truck.lastpoint.lng, y: this.truck.lastpoint.lat }
                : { x: 0, y: 0 }

            this.tmpAddressesIsocrona = []

            // Se è presente il veicolo selezionato aggiungo come possibilità di partenza la posizione attuale del veicolo
            if (this.truck.lastpoint) {
                this.tmpAddressesIsocrona.push({
                    referenceCoordinate: start_position,
                    iconLabel:
                        this.truck.category && this.truck.category.fa
                            ? JSON.parse(this.truck.category.fa).icon
                            : 'fak fa-fw fa-trattore-stradale',
                    label: this.__('planner.vehicle_actual_position')
                })
            }

            this.tmpAddressesIsocrona.push({
                referenceCoordinate: start_position,
                iconLabel: 'fa-solid fa-fw fa-crosshairs',
                action: 'selectOnMap',
                label: this.__('planner.select_on_map_option')
            })

            var lastWaypoint = null

            if (this.waypoints.at(-1) && this.waypoints.at(-1).coordinate) {
                lastWaypoint = this.waypoints.at(-1)
            } else if (this.waypoints.at(-2) && this.waypoints.at(-2).coordinate) {
                lastWaypoint = this.waypoints.at(-2)
            }

            if (lastWaypoint) {
                this.tmpAddressesIsocrona.push({
                    referenceCoordinate: {
                        x: lastWaypoint.coordinate.referenceCoordinate.x,
                        y: lastWaypoint.coordinate.referenceCoordinate.y
                    },
                    iconLabel: 'fa-solid fa-crosshairs',
                    label: this.__('planner.last_waypoint') + ' - ' + lastWaypoint.coordinate.label
                })
            }
        },
        loadProfiles() {
            this.$fetch(this.$env.GOLIA_API + '/geofleet/profiles')
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    this.profiles = res.data
                })
        },
        parseProfileEmission(emission) {
            let parsedEmission = ''

            switch (emission) {
                case 'euro6':
                    parsedEmission = 'EURO_6'
                    break

                default:
                    parsedEmission = 'EURO_6'
                    break
            }

            return parsedEmission
        },
        parseProfileLoadType(type) {
            let parsedEmission = ''

            switch (type) {
                case 'passengers':
                    parsedEmission = 'PASSENGER'
                    break
                case 'goods':
                    parsedEmission = 'GOODS'
                    break

                default:
                    parsedEmission = 'GOODS'
                    break
            }

            return parsedEmission
        },
        showFormAction(index) {
            // TODO STORE: move to a store commit 'updateWaypointProperty'
            this.waypoints[index].coordinate.showForm = !this.waypoints[index].coordinate.showForm
        },
        removeArea(index) {
            this.reachableAreas.splice(index, 1)
        },
        searchAddress(search, loading, waypoint) {
            var _this = this

            if (search) {
                loading(true)

                if (search != '')
                    this.$can('use', 'google_places')
                        ? this.gMapLocate(waypoint, loading, search, this)
                        : this.xlocate(loading, search, this)
                else {
                    // empty this array
                    this.$set(this, 'locationSearchResults', [])

                    this.$nextTick(() => {
                        _this.resetSelectOptions()
                    })
                }
            } else {
                if (this.locationSearchResults.length > 0) {
                    // empty this array
                    this.$set(this, 'locationSearchResults', [])
                }

                this.$nextTick(() => {
                    _this.resetSelectOptions()
                })
            }
        },
        addWaypointHandler() {
            if (!this.waypointsCheck) return
            else this.addStoreWaypoint({})
        },
        async optionSelectHandler(option, waypoint) {
            if (option) {
                let dataToUpdate = {
                    id: waypoint.id || waypoint,
                    coordinate: option
                }

                if (option.action == 'selectOnMap') {
                    dataToUpdate.updatingWaypoint = true

                    // Event for listening on map click
                    this.$emit('selectOnMap', waypoint)
                } else if (option.action == 'searchResource') {
                    dataToUpdate.updatingWaypoint = true
                    dataToUpdate.showAreaField = true

                    dataToUpdate.searchInfo = {}
                    dataToUpdate.searchInfo.type = option.resourceType

                    if (option.resourceType == 'borsa') {
                        dataToUpdate.searchInfo.pagination = {
                            page: 1,
                            perPage: 10,
                            total: 0
                        }
                    }
                } else if (option.type == 'gmap') {
                    dataToUpdate.coordinate.referenceCoordinate = await this.gMapPlace(option)

                    this.locationSearchResults = []
                }

                if (this.calcType == 'route') {
                    dataToUpdate.showForm = false
                    dataToUpdate.options = {}
                }

                // Update waypoint keeping the properties that are not in dataToUpdate
                if (this.calcType == 'route') {
                    this.$store.commit('updateWaypoint', {
                        waypoint: { ...waypoint, ...dataToUpdate }
                    })
                }
            } else {
                if (this.calcType == 'reachable_areas') {
                    this.isocronaReference = option
                    this.$emit('resetIsocrona')
                } else {
                    this.resetStoreWaypoint(waypoint)
                }

                this.$emit('resetClickMap')
                this.$emit('resetResource')
            }
        },
        searchResource(waypoint) {
            waypoint = waypoint || {}
            let lat = 0
            let lng = 0

            let waypointIndex = this.waypoints.findIndex((w) => {
                return w == waypoint
            })

            let last_waypoint = this.waypoints[waypointIndex - 1]

            if (waypoint.shadowWaypoint) {
                lat = waypoint.shadowWaypoint.coordinates[0]
                lng = waypoint.shadowWaypoint.coordinates[1]
            } else {
                lat = (last_waypoint || {}).coordinate
                    ? last_waypoint.coordinate.referenceCoordinate.y
                    : this.truck.lastpoint.lat
                lng = (last_waypoint || {}).coordinate
                    ? last_waypoint.coordinate.referenceCoordinate.x
                    : this.truck.lastpoint.lng
            }

            let form_value = waypoint.searchInfo.areaValue

            if (waypoint.searchInfo.areaUnit == 'min') {
                waypoint.searchInfo.isocronaValue = btoa(
                    JSON.stringify(
                        this.createReachableAreasBody(
                            [
                                {
                                    timing: parseInt(form_value)
                                }
                            ],
                            { x: lng, y: lat }
                        )
                    )
                )
            }

            this.$emit('searchResource', waypoint, lat, lng)
        },
        selectResource(resource) {
            var waypoint_label = null

            if (this.calcType == 'route') {
                var waypointToBeUpdated = this.getUpdatingWaypoint.waypoint

                if (resource.type == 'fuel') {
                    let name =
                        resource.reference.name ||
                        resource.reference.id + ' - ' + resource.reference.city

                    if (resource.reference.flag)
                        waypoint_label =
                            resource.reference.flag + ' - ' + name.substr(0, 15) + '...'
                    else waypoint_label = name.substr(0, 15) + '...'
                } else if (resource.type == 'borsa') {
                    waypoint_label =
                        this.__('planner.alpega') +
                        ' - ' +
                        resource.reference.pickUp.location.address.city +
                        ' - ' +
                        this.__('planner.pickup')
                } else if (resource.type == 'borsa-timocom') {
                    waypoint_label =
                        this.__('planner.timocom') +
                        ' - ' +
                        resource.reference.pickUp.location.address.city +
                        ' - ' +
                        this.__('planner.pickup')
                } else {
                    waypoint_label = resource.name
                }

                waypointToBeUpdated.coordinate.referenceCoordinate = resource.coordinate
                waypointToBeUpdated.coordinate.label = waypoint_label
                waypointToBeUpdated.coordinate.type = resource.type
                waypointToBeUpdated.coordinate.action = 'selectResource'
                waypointToBeUpdated.coordinate.resource = resource
                waypointToBeUpdated.updatingWaypoint = false

                this.$store.commit('updateWaypoint', { waypoint: waypointToBeUpdated })

                this.$emit('resetResource')

                if (resource.type == 'borsa') {
                    let deliveryWaypoint = { coordinate: {} }

                    this.addStoreWaypoint(deliveryWaypoint)

                    deliveryWaypoint.id = this.waypoints.at(-1).id
                    deliveryWaypoint.coordinate.referenceCoordinate = resource.delivery.coordinate
                    deliveryWaypoint.coordinate.label =
                        this.__('planner.alpega') +
                        ' - ' +
                        resource.reference.delivery.location.address.city +
                        ' - ' +
                        this.__('planner.delivery')
                    ;(deliveryWaypoint.coordinate.icon_unicode = '#xe03c'),
                        (deliveryWaypoint.coordinate.type = 'borsa.delivery')
                    deliveryWaypoint.coordinate.action = 'selectResource'
                    deliveryWaypoint.coordinate.resource = resource

                    this.$store.commit('updateWaypoint', { waypoint: deliveryWaypoint })
                }

                if (resource.type == 'borsa-timocom') {
                    waypoint_label =
                        this.__('planner.timocom') +
                        ' - ' +
                        resource.reference.delivery.location.address.city +
                        ' - ' +
                        this.__('planner.delivery')

                    let deliveryWaypoint = { coordinate: {} }

                    this.addStoreWaypoint(deliveryWaypoint)

                    deliveryWaypoint.id = this.waypoints.at(-1).id
                    deliveryWaypoint.coordinate.referenceCoordinate = resource.delivery.coordinate
                    deliveryWaypoint.coordinate.label =
                        this.__('planner.timocom') +
                        ' - ' +
                        resource.reference.delivery.location.address.city +
                        ' - ' +
                        this.__('planner.delivery')
                    ;(deliveryWaypoint.coordinate.icon_unicode = '#xe03c'),
                        (deliveryWaypoint.coordinate.type = 'borsa-timocom')
                    deliveryWaypoint.coordinate.action = 'selectResource'
                    deliveryWaypoint.coordinate.resource = resource

                    this.$store.commit('updateWaypoint', { waypoint: deliveryWaypoint })
                }
            } else {
                if (resource.type == 'fuel') {
                    let name =
                        resource.reference.name ||
                        resource.reference.id + ' - ' + resource.reference.city

                    if (resource.reference.flag)
                        waypoint_label =
                            resource.reference.flag + ' - ' + name.substr(0, 15) + '...'
                    else waypoint_label = name.substr(0, 15) + '...'
                } else {
                    waypoint_label = resource.name || resource.id
                }

                let isocronaWaypoint = { coordinate: {} }

                if (this.waypointsCheck) {
                    this.addStoreWaypoint(isocronaWaypoint)
                }

                isocronaWaypoint.id = this.waypoints.at(-1).id
                isocronaWaypoint.coordinate.referenceCoordinate = resource.coordinate
                isocronaWaypoint.coordinate.label = waypoint_label
                isocronaWaypoint.coordinate.action = 'searchResource'
                isocronaWaypoint.coordinate.resource = resource

                this.$store.commit('updateWaypoint', { waypoint: isocronaWaypoint })

                this.calcType = 'route'

                this.$emit('resetResource')
                this.$emit('resetUtilsMarkers')

                this.resetIsocrona()
            }

            this.$root.$emit('bv::toggle::collapse', 'step-middle')
            this.$root.$emit('bv::toggle::collapse', 'step-2')
        },
        draggedWaypoints() {
            this.drag = false

            this.updateStoreWaypoints(this.waypoints)
        },
        // highlightWaypoint(waypoint, highlight) {

        //     this.$set(waypoint, 'highlighted', highlight)

        //     if (highlight) {
        //         _.delay(() => {
        //             this.$set(waypoint, 'highlighted', false)
        //         }, 1000)
        //     }
        // },
        focusResource(resource) {
            this.$emit('focusResource', resource)
        },
        xlocate: debounce((loading, search, vm) => {
            var myHeaders = new Headers()
            myHeaders.append(
                'Authorization',
                'Basic eHRvazpENjY4Nzg3Ni1DQUU2LTQzMkItQUUwNy1CQTVCRkFGRjQxRjM='
            )
            myHeaders.append('Content-Type', 'application/json')

            var raw = JSON.stringify({
                $type: 'SearchByTextRequest',
                requestProfile: {
                    mapLanguage: 'x-ptv-DFT'
                },
                text: search
            })

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw
            }

            fetch(
                'https://xserver2-europe.cloud.ptvgroup.com/services/rs/XLocate/searchLocations',
                requestOptions
            )
                .then((response) => response.json())
                .then((json) => {
                    loading(false)

                    if (vm.calcType == 'route') {
                        vm.locationSearchResults = json.results.map((r) => {
                            return {
                                action: 'searchOnMap',
                                referenceCoordinate: r.location.referenceCoordinate,
                                label: r.location.formattedAddress
                            }
                        })
                    } else {
                        vm.tmpAddressesIsocrona = json.results.map((r) => {
                            return {
                                action: 'searchOnMap',
                                referenceCoordinate: r.location.referenceCoordinate,
                                label: r.location.formattedAddress
                            }
                        })
                    }
                })
        }, 250),
        gMapLocate: debounce((waypoint, loading, search, vm) => {
            let autocompleteService = new vm.gmaps.AutocompleteService()

            waypoint.sessionToken = waypoint.sessionToken || new vm.gmaps.AutocompleteSessionToken()

            autocompleteService.getPlacePredictions(
                {
                    input: search,
                    sessionToken: waypoint.sessionToken,
                    language: vm.$locale()
                },
                (res) => {
                    loading(false)

                    if (vm.calcType == 'route') {
                        vm.locationSearchResults = res.map((r) => {
                            return {
                                action: 'searchOnMap',
                                type: 'gmap',
                                placeId: r.place_id,
                                label: r.description,
                                sessionToken: waypoint.sessionToken,
                                referenceCoordinate: { x: 0, y: 0 }
                            }
                        })
                    } else {
                        vm.tmpAddressesIsocrona = res.map((r) => {
                            return {
                                action: 'searchOnMap',
                                type: 'gmap',
                                placeId: r.place_id,
                                label: r.description,
                                sessionToken: waypoint.sessionToken,
                                referenceCoordinate: { x: 0, y: 0 }
                            }
                        })
                    }

                    if (waypoint.updatingWaypoint) waypoint.updatingWaypoint = false
                }
            )
        }, 250),
        gMapPlace: function (option) {
            return new Promise((resolved, rejected) => {
                let placeService = new this.gmaps.PlacesService(document.createElement('div'))

                placeService.getDetails(
                    {
                        placeId: option.placeId,
                        sessionToken: option.sessionToken,
                        fields: ['geometry.location']
                    },
                    (res, status) => {
                        if (status == 'OK')
                            return resolved({
                                x: res.geometry.location.lng(),
                                y: res.geometry.location.lat()
                            })

                        rejected(status)
                    }
                )
            })
        },
        setActionButton() {
            // Open second accordion
            var step2 = document.getElementById('step-2')

            if (this.calcType == 'route') {
                if (this.waypoints.length > 1 && this.waypointsCheck) {
                    this.computeRoute()
                } else {
                    if (!step2.classList.contains('show')) {
                        this.$root.$emit('bv::toggle::collapse', 'step-2')
                    } else {
                        // add class .show
                        step2.classList.add('error-animation')

                        setTimeout(() => {
                            step2.classList.remove('error-animation')
                        }, 1000)
                    }
                }
            } else {
                if (
                    this.isocronaReference &&
                    Object.prototype.hasOwnProperty.call(this.reachableAreas[0], 'timing') &&
                    this.reachableAreas[0].timing != ''
                ) {
                    this.computeReachableAreas()
                } else {
                    if (!step2.classList.contains('show')) {
                        this.$root.$emit('bv::toggle::collapse', 'step-2')
                    } else {
                        // add class .show
                        step2.classList.add('error-animation')

                        setTimeout(() => {
                            step2.classList.remove('error-animation')
                        }, 1000)
                    }
                }
            }
        },
        exportPTVNavigator() {
            var blob = new Blob([atob(this.routeResponse.guidedNavigationRoute)], {
                type: 'text/plain'
            })

            saveAs(
                blob,
                this.savedRoutes.selectedTrip
                    ? this.savedRoutes.selectedTrip.title
                    : this.moment().format('YYYY_MM_DD') + '.bcr'
            )
        },
        loadBreaksAndRests() {
            this.loadedBreaksAndRests = true

            this.computeRoute({
                polyline: false,
                emissions: false,
                monetaryCostsReport: false,
                eventTypes: ['WAYPOINT_EVENT', 'TOUR_EVENT'],
                guidedNavigationRoute: false,
                saveBreaksAndRests: true
            })
        },
        loadAlternativeRoutes() {
            this.computeRoute({
                alternativeRoutes: true,
                encodedPath: true
            })

            // Poi quando si seleziona uno dei percorsi, bisogna rifare un calculateRoute con
            // {
            //     waypoints: [
            //         {
            //             $type: "PathWaypoint",
            //             encodedPath: "encodedPath"
            //         }
            //     ]
            // }
        },
        computeRoute(calcParams) {
            this.$emit('input', {})
            this.loading = true
            var _this = this

            let params = calcParams || null

            if (
                this.breaksInWaypoints.unelaborated.length > 0 ||
                this.breaksInWaypoints.elaborated.length > 0
            )
                this.routeInfo.includePlafond = false

            if (!params) this.loadedBreaksAndRests = false

            var myHeaders = new Headers()
            myHeaders.append(
                'Authorization',
                'Basic eHRvazpENjY4Nzg3Ni1DQUU2LTQzMkItQUUwNy1CQTVCRkFGRjQxRjM='
            )
            myHeaders.append('Content-Type', 'application/json')

            let body = {
                requestProfile: {
                    vehicleProfile: {
                        engine: {
                            engineType: 'COMBUSTION',
                            fuelType: 'DIESEL'
                        }
                    },
                    routingProfile: {
                        course: {
                            distanceTimeWeighting: this.routeInfo.distanceTimeWeighting,
                            toll: {
                                tollPenalty: 500 - (this.routeInfo.tollPenalty + 95)
                            }
                        }
                    },
                    featureLayerProfile: {
                        themes: [
                            { id: 'PTV_SpeedPatterns', enabled: true },
                            { id: 'PTV_TrafficIncidents', enabled: true },
                            { id: 'PTV_TruckAttributes', enabled: true }
                        ]
                    },
                    userLanguage: this.$authProcess.getParams('Lingua') || 'it-IT'
                },
                resultFields: {
                    legs: {
                        enabled: true,
                        polyline: false
                    },
                    waypoints: true,
                    polyline: params && params.polyline ? params.polyline : true,
                    emissions: params && params.emissions ? params.emissions : true,
                    tourReport: true,
                    report: true,
                    monetaryCostsReport:
                        params && params.monetaryCostsReport ? params.monetaryCostsReport : true,
                    eventTypes:
                        params && params.eventTypes
                            ? params.eventTypes
                            : [
                                  'MANEUVER_EVENT',
                                  'TOLL_EVENT',
                                  'WAYPOINT_EVENT',
                                  'COUNTRY_EVENT',
                                  'TOUR_EVENT'
                              ],
                    alternativeRoutes:
                        params && params.alternativeRoutes ? params.alternativeRoutes : false,
                    toll: {
                        enabled: true,
                        sections: true
                    },
                    guidedNavigationRoute:
                        params && params.guidedNavigationRoute ? params.guidedNavigationRoute : true
                },
                routeOptions: {
                    geographicRestrictions: {
                        prohibitedSegmentsByIntersectingPolylines: [
                            {
                                wkt: 'LINESTRING(12.545259 45.667441, 12.545848 45.667322)'
                            }
                        ]
                    },
                    emissionOptions: {
                        valueScenarios: [
                            {
                                $type: 'EmissionValueScenario_HBEFA_4',
                                scenarios: ['CURRENT_ROUTE']
                            },
                            {
                                $type: 'EmissionValueScenario_HBEFA_3_2',
                                scenarios: ['CURRENT_ROUTE']
                            }
                        ]
                    },
                    tollOptions: {
                        returnZeroCosts: true
                    },
                    timeConsideration: {
                        $type:
                            this.routeInfo.startMode == 'end'
                                ? 'ExactTimeConsiderationAtArrival'
                                : 'ExactTimeConsiderationAtStart',
                        referenceTime: this.routeInfo.startTime.start.toISOString()
                    },
                    currency: 'EUR'
                },
                waypoints: this.waypoints.reduce((c, w) => {
                    if (w.coordinate) {
                        if (w.shadowWaypoint) {
                            if (w.coordinate.action != 'break' && w.coordinate.action != 'rest') {
                                // esegui qualcosa se c'è w.coordinate, w.shadowWaypoint e w.id
                                c.push({
                                    $type: 'OffRoadWaypoint',
                                    location: {
                                        offRoadCoordinate: w.coordinate.referenceCoordinate,
                                        sideOfStreetRestriction: 'ANY_SIDE'
                                    }
                                })
                            }
                        } else {
                            // esegui qualcosa se c'è w.coordinate e non c'è w.shadowWaypoint
                            c.push({
                                $type: 'OffRoadWaypoint',
                                location: {
                                    offRoadCoordinate: w.coordinate.referenceCoordinate,
                                    sideOfStreetRestriction: 'ANY_SIDE'
                                }
                            })
                        }
                    }

                    return c
                }, [])
            }

            let profile = null

            if (
                this.routeInfo.chosenProfile &&
                (profile = this.profiles.find((p) => p.id == this.routeInfo.chosenProfile))
            ) {
                body.requestProfile.vehicleProfile = {
                    engine: {
                        engineType: 'COMBUSTION',
                        fuelType: profile.data.fuelType || 'DIESEL',
                        emissionStandardEU: this.parseProfileEmission(profile.data.emissionClass)
                    },
                    weight: {
                        emptyWeight: profile.data.emptyWeight,
                        loadWeight: 0,
                        totalPermittedWeight: profile.data.totalPermittedWeight
                    },
                    dimensions: {
                        height: profile.data.height || 400,
                        length: profile.data.length || 1650,
                        width: profile.data.width || 254
                    },
                    axle: {
                        axleLoad: 11500,
                        numberOfAxles: profile.data.numberOfAxles || 5
                    },
                    load: {
                        loadType: this.parseProfileLoadType(profile.data.loadType)
                    },
                    numberOfPassengers: profile.data.maximumPermittedNumberOfPassengers || 1
                }
            }

            let emissionClass = this.truckPresence ? this.routeInfo.selectedCo2Class : null

            if (!isNaN(emissionClass) && emissionClass > 0) {
                body.requestProfile.vehicleProfile.engine.co2EmissionClassEU = emissionClass
            }

            if (this.routeInfo.includePlafond) {
                body.routeOptions.tourRestrictions = {
                    configurableWorkingHoursPreset:
                        this.plafondConfig.configurableWorkingHoursPreset,
                    workLogbookSummary: {
                        lastTimeTheDriverWorked:
                            this.plafondConfig.lastTimeTheDriverWorked.start.toISOString(),
                        configurableWorkingHoursLogbookSummary: {
                            accumulatedDrivingTimeSinceLastBreak:
                                (this.plafondConfig.accumulatedDrivingTimeSinceLastBreak || 0) *
                                60 *
                                60,
                            accumulatedDrivingTimeSinceLastDailyRest:
                                (this.plafondConfig.accumulatedDrivingTimeSinceLastDailyRest || 0) *
                                60 *
                                60,
                            accumulatedTravelTimeSinceLastDailyRest:
                                (this.plafondConfig.accumulatedTravelTimeSinceLastDailyRest || 0) *
                                60 *
                                60
                        }
                    }
                }
            }

            body.routeOptions.monetaryCostOptions = {
                costPerKilometer: this.routeInfo.costPerKilometer || 1.2,
                workingCostPerHour: this.routeInfo.workingCostPerHour || 20.5
            }

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(body)
            }

            fetch(
                'https://xserver2-europe.cloud.ptvgroup.com/services/rs/XRoute/experimental/calculateRoute/',
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    this.routeResponse = result

                    var allWaypoints = []
                    var consideredWaypoints = []

                    if (this.routeInfo.includePlafond)
                        consideredWaypoints = result.events.filter(
                            (w) =>
                                w.$type == 'WaypointEvent' ||
                                (w.$type == 'TourEvent' &&
                                    (w.tourEventTypes.includes('BREAK') ||
                                        w.tourEventTypes.includes('DAILY_REST')))
                        )
                    else
                        consideredWaypoints = result.events.filter(
                            (w) => w.$type == 'WaypointEvent'
                        )

                    consideredWaypoints.forEach((event, index) => {
                        let indexToUse = index
                        let waypointsToUse = _this.waypoints

                        if (this.routeInfo.includePlafond) indexToUse = event.waypointIndex

                        if (this.breaksInWaypoints.unelaborated.length > 0)
                            // Use _this.waypoints to get the correct index
                            waypointsToUse = _this.waypoints.filter(
                                (w) =>
                                    !w.shadowWaypoint ||
                                    (w.shadowWaypoint &&
                                        w.coordinate.action != 'break' &&
                                        w.coordinate.action != 'rest')
                            )

                        if (event.$type == 'WaypointEvent') {
                            let resource = ''

                            if (waypointsToUse[indexToUse].coordinate) {
                                if (waypointsToUse[indexToUse].coordinate.resourceType)
                                    resource = waypointsToUse[indexToUse].coordinate.resourceType
                                else if (waypointsToUse[indexToUse].coordinate.resource)
                                    resource = waypointsToUse[indexToUse].coordinate.resource.type
                                else resource = 'selectonmap'
                            }

                            let waypoint = {
                                waypointIndex: event.waypointIndex,
                                coordinate: [event.coordinate.y, event.coordinate.x],
                                label: waypointsToUse[indexToUse].coordinate.label,
                                iconLabel: waypointsToUse[indexToUse].coordinate.iconLabel
                                    ? waypointsToUse[indexToUse].coordinate.iconLabel
                                    : null,
                                resourceType: resource
                            }

                            allWaypoints.push(waypoint)
                        }

                        if (event.$type == 'TourEvent') {
                            let label = ''
                            let action = ''
                            let resourceType = ''
                            let shadowWaypointType = ''
                            let icon = ''

                            if (event.tourEventTypes.includes('BREAK')) {
                                label = this.__('break')
                                action = 'break'
                                resourceType = 'break'
                                shadowWaypointType = 'break'
                                icon = '#xf2f2'
                            } else if (event.tourEventTypes.includes('DAILY_REST')) {
                                label = this.__('daily_rest')
                                action = 'daily_rest'
                                resourceType = 'daily_rest'
                                shadowWaypointType = 'daily_rest'
                                icon = '#xf236'
                            } else if (event.tourEventTypes.includes('SERVICE')) {
                                label = this.__('violation')
                                // action = 'violation'
                                resourceType = 'violation'
                                shadowWaypointType = 'violation'
                            }

                            let waypoint = {
                                coordinate: [event.coordinate.y, event.coordinate.x],
                                label: label,
                                resourceType: resourceType,
                                action: action,
                                travelTimeFromStart: event.travelTimeFromStart,
                                violated: event.tourViolations ? true : false,
                                icon_unicode: icon,
                                shadowWaypoint: {
                                    type: shadowWaypointType,
                                    coordinates: [event.coordinate.y, event.coordinate.x]
                                }
                            }

                            allWaypoints.push(waypoint)

                            if ((params || {}).saveBreaksAndRests) {
                                let waypointToInject = {
                                    coordinate: {
                                        referenceCoordinate: {
                                            x: event.coordinate.x,
                                            y: event.coordinate.y
                                        },
                                        label: label,
                                        resourceType: resourceType,
                                        action: action,
                                        violated: event.tourViolations ? true : false,
                                        travelTime: event.travelTimeFromStart,
                                        icon_unicode: icon
                                    },
                                    shadowWaypoint: {
                                        type: shadowWaypointType,
                                        coordinates: [event.coordinate.y, event.coordinate.x]
                                    }
                                }

                                this.$store.commit('injectWaypoint', {
                                    waypoint: waypointToInject,
                                    index: index
                                })
                            }
                        }
                    })

                    this.$emit('input', {
                        polyline: result.polyline.plain.polyline.map((p) => [p.y, p.x]),
                        waypoints: allWaypoints,
                        alternativeRoutes: result.alternativeRoutes
                            ? result.alternativeRoutes
                            : null,
                        alternativeRoutesPolylines: result.alternativeRoutes
                            ? result.alternativeRoutes.map((r) =>
                                  r.polyline.plain.polyline.map((p) => [p.y, p.x])
                              )
                            : null
                    })

                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },
        createReachableAreasBody(reachableAreas, waypoint) {
            let quality =
                reachableAreas.reduce((c, a) => {
                    parseInt(a.timing) > c

                    c = a.timing

                    return c
                }, 0) <= 90

            return {
                requestProfile: {
                    featureLayerProfile: {
                        themes: [
                            { id: 'PTV_SpeedPatterns', enabled: true },
                            { id: 'PTV_TrafficIncidents', enabled: true },
                            { id: 'PTV_TruckAttributes', enabled: true }
                        ]
                    },
                    userLanguage: 'it-IT'
                },
                reachableAreasOptions: {
                    calculationMode: quality ? 'QUALITY' : 'PERFORMANCE',
                    drivingDirection: this.routeInfo.startMode == 'end' ? 'INBOUND' : 'OUTBOUND',
                    timeConsideration: {
                        $type:
                            this.routeInfo.startMode == 'end'
                                ? 'ExactTimeConsiderationAtArrival'
                                : 'ExactTimeConsiderationAtStart',
                        referenceTime: this.routeInfo.startTime.start.toISOString()
                    },
                    horizons: reachableAreas.reduce((c, a) => {
                        if (a.timing)
                            c.push({
                                $type: 'TravelTimeBasedHorizon',
                                travelTime: a.timing * 60
                            })

                        return c
                    }, [])
                },
                waypoint: {
                    $type: 'OffRoadWaypoint',
                    location: {
                        offRoadCoordinate: waypoint,
                        sideOfStreetRestriction: 'ANY_SIDE'
                    }
                }
            }
        },
        computeReachableAreas(reachableAreas, waypoint) {
            this.$emit('input', {})
            this.loading = true
            var searchInfo = null
            var totalArea = 0

            var myHeaders = new Headers()
            myHeaders.append(
                'Authorization',
                'Basic eHRvazpENjY4Nzg3Ni1DQUU2LTQzMkItQUUwNy1CQTVCRkFGRjQxRjM='
            )
            myHeaders.append('Content-Type', 'application/json')

            waypoint = waypoint || this.isocronaReference.referenceCoordinate

            if (!waypoint) return false

            //Reorder reachable areas by timing asc
            this.reachableAreas = this.reachableAreas.sort((a, b) => a.timing - b.timing)

            let body = this.createReachableAreasBody(
                reachableAreas || this.reachableAreas,
                waypoint
            )

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(body)
            }

            fetch(
                'https://xserver2-europe.cloud.ptvgroup.com/services/rs/XRoute/calculateReachableAreas',
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    this.$emit('input', {
                        polygons: result.polygons.map((polygon) => {
                            return polygon.plain.polygonRings.map((p) => {
                                return p.polyline.map((l) => [l.y, l.x])
                            })
                        })
                    })

                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })

            if (this.isocronaSearchInfo) {
                let types = []

                totalArea += this.reachableAreas.reduce((prev, curr) => {
                    return parseInt(prev.timing) + parseInt(curr.timing)
                })

                this.isocronaSearchInfo.map((e) => {
                    types.push(e)
                })

                searchInfo = {
                    type: types,
                    areaValue: totalArea.timing,
                    areaUnit: 'min',
                    isocronaValue: btoa(JSON.stringify(body))
                }

                this.$emit('searchResource', searchInfo, waypoint.y, waypoint.x)
            }
        },
        abortResourceSearch() {
            var waypointToReset = this.getUpdatingWaypoint.waypoint

            this.$store.commit('resetWaypoint', waypointToReset)
            this.$emit('resetResource')

            if (this.value.polygons) this.resetIsocrona()

            this.$root.$emit('bv::toggle::collapse', 'step-2')
        },
        editRoute() {
            this.routeResponse = null
            this.$emit('editRoute')
        },
        saveRoute(override) {
            let objToSend = {
                title: this.savedRouteName,
                timeConsideration:
                    this.routeInfo.startMode == 'end'
                        ? 'ExactTimeConsiderationAtArrival'
                        : 'ExactTimeConsiderationAtStart',
                referenceTime: this.routeInfo.startTime.start.toISOString(),
                avoidToll: this.avoidToll,
                tollPenalty: this.routeInfo.tollPenalty,
                distanceTimeWeighting: this.routeInfo.distanceTimeWeighting,
                userLanguage: this.$authProcess.getParams('Lingua') || 'it-IT',
                emissionOptions: [
                    { $type: 'EmissionValueScenario_HBEFA_4', scenarios: ['CURRENT_ROUTE'] },
                    { $type: 'EmissionValueScenario_HBEFA_3_2', scenarios: ['CURRENT_ROUTE'] }
                ],
                waypoints: cloneDeep(this.waypoints),
                vehicleProfile: {},
                monetaryCostOptions: {
                    costPerKilometer: this.routeInfo.costPerKilometer || 1.2,
                    workingCostPerHour: this.routeInfo.workingCostPerHour || 20.5
                },
                result: {
                    distance: this.routeResponse.distance,
                    travelTime: this.routeResponse.travelTime,
                    toll: this.routeResponse.toll,
                    tourReport: this.routeResponse.tourReport,
                    monetaryCostsReport: this.routeResponse.monetaryCostsReport
                }
            }

            let profile = null

            if (
                this.routeInfo.chosenProfile &&
                (profile = this.profiles.find((p) => p.id == this.routeInfo.chosenProfile))
            ) {
                objToSend.vehicleProfile = {
                    engine: {
                        engineType: 'COMBUSTION',
                        fuelType: profile.fuelType || 'DIESEL',
                        emissionStandardEU: profile.emissionClass
                    },
                    weight: {
                        emptyWeight: profile.emptyWeight,
                        loadWeight: 0,
                        totalPermittedWeight: profile.totalPermittedWeight
                    },
                    dimensions: {
                        height: profile.height || 400,
                        length: profile.length || 1650,
                        width: profile.width || 254
                    },
                    axle: {
                        axleLoad: 11500,
                        numberOfAxles: profile.numberOfAxles || 5
                    },
                    load: {
                        loadType: profile.loadType
                    },
                    numberOfPassengers: profile.maximumPermittedNumberOfPassengers || 1
                }
            }

            let saveUrl = 'https://plannerws.goliaweb.it/route'

            if (override) {
                saveUrl = 'https://plannerws.goliaweb.it/route/' + this.savedRoutes.selectedTrip._id
            }

            this.$fetch(saveUrl, {
                'X-Company': this.$authProcess.getSignedTenantToken(),
                method: override ? 'PUT' : 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(objToSend),
                redirect: 'follow'
            })
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    this.$bvModal.hide('modal-save-route')

                    if (res.data) {
                        this.$snotify.success(this.__('planner.savedroutes.success_saved_route'), {
                            position: 'centerTop'
                        })
                    } else if (res.errors) {
                        this.$snotify.error(this.__('planner.savedroutes.error_saved_route'), {
                            position: 'centerTop'
                        })
                    }

                    this.savedRoutes.selectedTrip = objToSend

                    if (!override) {
                        this.savedRoutes.selectedTrip._id = res.data._id
                        this.savedRoutes.savedTrips.push(this.savedRoutes.selectedTrip)
                    } else {
                        this.savedRoutes.selectedTrip._id = res.data
                        var index = this.savedRoutes.savedTrips.findIndex(
                            (trip) => trip._id == this.savedRoutes.selectedTrip._id
                        )

                        if (index > -1)
                            this.savedRoutes.savedTrips[index] = this.savedRoutes.selectedTrip
                    }
                })
        },
        loadSavedTrips() {
            let url =
                'https://plannerws.goliaweb.it/route?paginate=' +
                this.savedRoutes.savedTripsPagination.perPage
            url += '&page=' + (this.savedRoutes.savedTripsPagination.currentPage - 1)

            this.savedRoutes.savedTrips = []
            this.savedRoutes.savedTripsLoading = true

            this.$fetch(url, {
                'X-Company': this.$authProcess.getSignedTenantToken()
            })
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    if (res.data)
                        this.savedRoutes.savedTrips = res.data.filter(
                            (trip) => trip.waypoints.length > 0
                        )

                    if (res.meta) this.savedRoutes.savedTripsPagination.totalItems = res.meta.items

                    this.savedRoutes.savedTripsLoading = false
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        async loadTrip(trip) {
            this.resetRoute({
                emitParent: true,
                parent: {
                    resources: true,
                    waypoints: false,
                    route: true,
                    map: true
                },
                isocrona: true,
                selectOptions: true,
                results: true,
                selectedTrip: true
            })

            let waypointsToAdd = cloneDeep(trip.waypoints)

            // Se è presente un waypoint senza azione (cioè "planner.vehicle_actual_position"), lo imposto a selectOnMap
            let waypointWithoutAction = waypointsToAdd.find(
                (waypoint) => !waypoint.coordinate.action
            )

            if (waypointWithoutAction) {
                waypointWithoutAction.coordinate.action = 'selectOnMap'

                var address = await this.$reverseGeocoding({
                    lat: waypointWithoutAction.coordinate.referenceCoordinate.y,
                    lng: waypointWithoutAction.coordinate.referenceCoordinate.x,
                    full: true
                })
                waypointWithoutAction.coordinate.label = address
            }

            // this.$emit('setWaypoints', waypointsToAdd);
            this.$store.commit('updateWaypoints', waypointsToAdd)

            this.routeInfo.startMode =
                trip.timeConsideration == 'ExactTimeConsiderationAtArrival' ? 'end' : 'start'

            this.routeInfo.startTime = {
                start:
                    this.routeInfo.startMode == 'start'
                        ? this.moment(trip.referenceTime)
                        : this.moment().startOf('day'),
                end:
                    this.routeInfo.startMode == 'end'
                        ? this.moment(trip.referenceTime)
                        : this.moment().endOf('day')
            }

            this.avoidToll = trip.avoidToll
            this.routeInfo.tollPenalty = trip.tollPenalty || -99
            this.routeInfo.distanceTimeWeighting = trip.distanceTimeWeighting
                ? trip.distanceTimeWeighting
                : 0
            this.routeInfo.costPerKilometer = trip.monetaryCostOptions.costPerKilometer
            this.routeInfo.workingCostPerHour = trip.monetaryCostOptions.workingCostPerHour

            this.savedRouteName = trip.title
            this.savedRoutes.selectedTrip = trip

            this.$bvModal.hide('modal-saved-trips')
        },
        deleteSavedTrip(trip) {
            let url = 'https://plannerws.goliaweb.it/route/' + trip._id
            this.savedRoutes.savedTripsLoading = true

            this.$fetch(url, {
                'X-Company': this.$authProcess.getSignedTenantToken(),
                method: 'DELETE'
            })
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    if (res.data) {
                        this.$snotify.success(
                            this.__('planner.savedroutes.success_deleted_route'),
                            { position: 'centerTop' }
                        )
                    } else if (res.errors) {
                        this.$snotify.error(this.__('planner.savedroutes.error_deleted_route'), {
                            position: 'centerTop'
                        })
                    }

                    this.savedRoutes.savedTripsLoading = false
                    this.savedRoutes.selectedTrip = null

                    //delete savedTrips related trip
                    var tripToDelete = this.savedRoutes.savedTrips.findIndex(
                        (t) => t._id == trip._id
                    )
                    if (tripToDelete > -1) this.savedRoutes.savedTrips.splice(tripToDelete, 1)
                })
        },
        resetRoute(config) {
            if (config) {
                if (config.emitParent)
                    this.$emit('resetRoute', config.parent ? config.parent : null)

                if (config.isocrona) {
                    this.isocronaSearchInfo = [{}]
                    this.reachableAreas = [{}]
                    this.isocronaReference = null
                }

                if (config.selectOptions) this.resetSelectOptions()

                if (config.results) this.routeResponse = null

                if (config.selectedTrip) this.savedRoutes.selectedTrip = null
            } else {
                this.$emit('resetRoute')
                this.isocronaSearchInfo = [{}]
                this.reachableAreas = [{}]
                this.isocronaReference = null

                this.routeInfo.startMode = 'start'
                this.routeInfo.startTime = { start: this.moment(), end: this.moment().endOf('day') }
                this.routeInfo.chosenProfile = null
                this.routeInfo.costPerKilometer = null
                this.routeInfo.workingCostPerHour = null
                this.avoidToll = false
                this.routeInfo.tollPenalty = 405
                this.shorterWay = false
                this.routeInfo.includePlafond = false
                this.routeInfo.distanceTimeWeighting = 50

                this.resetSelectOptions()

                this.routeResponse = null
                this.savedRoutes.selectedTrip = null
                this.loadedBreaksAndRests = false
            }
        },
        resetIsocrona() {
            this.$emit('resetIsocrona')
            this.isocronaSearchInfo = [{}]
            this.reachableAreas = [{}]
            this.isocronaReference = null
        },
        ...mapMutations({
            updateStoreWaypoints: 'updateWaypoints',
            updateStoreWaypoint: 'updateWaypoint',
            updateStoreWaypointProperty: 'updateWaypointProperty',
            addStoreWaypoint: 'addWaypoint',
            removeStoreWaypoint: 'removeWaypoint',
            resetStoreWaypoint: 'resetWaypoint'
        })
    }
}
</script>

<style scoped lang="scss">
.route-planner {
    font-size: 14px;
}
.route-planner ::v-deep(.card),
.route-planner ::v-deep(.card) .card-header {
    border: 0px;
    border-radius: 25px;
}
.route-planner ::v-deep(.card) {
    background: var(--main-inner-bg-color);
}
.route-planner ::v-deep(.card) .card-header {
    background: var(--main-card-header-bg-color);
    color: var(--main-text-color);
}
.route-planner ::v-deep(.card) .card-header button {
    background: none;
    border: 0px;
    width: 100%;
    text-align: left;
    font-family: 'Stolzl-Bold';
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--main-text-color);
}
.route-planner ::v-deep(.card) .card-header button.not-collapsed i.fa.fa-chevron-down {
    display: none;
}
.route-planner ::v-deep(.card) .card-header button.collapsed i.fa.fa-chevron-up {
    display: none;
}
.route-planner ::v-deep(.card) .card-header button:focus {
    box-shadow: none;
}
.r-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.date-picker {
    align-self: center;

    background-color: #fff;
    border-radius: 5px;
    padding-right: 5px;
    padding-left: 5px;

    min-width: 305px;
}
.add-waypoint {
    background-color: #4bcc87;
    border-radius: 50%;

    width: 24px;
    height: 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    cursor: pointer;

    align-self: center;
}
.delete-waypoint {
    background-color: #f8385b;
    border-radius: 50%;

    min-width: 24px;
    height: 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    cursor: pointer;

    align-self: center;
}
.select-waypoint {
    background-color: var(--dark-blue);
    border-radius: 50%;

    width: 24px;
    height: 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    cursor: pointer;

    align-self: center;
}
.options-waypoint {
    cursor: pointer;
}
.waypoint-wrapper {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
    padding: 8px;
    border-radius: 8px;

    &.aree-wrapper {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    &.updating-waypoint {
        background-color: var(--main-inner-bg-color);
        border-radius: 25px;
        padding: 12px;
    }

    ::v-deep(.form-control),
    ::v-deep(.form-select) {
        padding-top: 8px;
        padding-bottom: 8px;
    }
}
.wrapper-waypoint-location {
    display: flex;
    flex-direction: row;
    align-items: center;
    // flex-wrap: wrap;
    gap: 8px;
}
.v-select,
.v-select ::v-deep(.vs__dropdown-toggle) {
    background-color: #fff;
    border-radius: 12px;
}
.v-select ::v-deep(.vs__dropdown-toggle) {
    padding: 6px;
}
.v-select ::v-deep(ul) {
    margin-top: -5px;
}
.v-select ::v-deep(.vs__search) {
    font-size: 14px;
}
.v-select ::v-deep(.vs__selected),
.v-select.vs--open ::v-deep(.vs__selected) {
    position: relative;
}
.v-select.vs--open ::v-deep(.vs__selected) {
    position: relative;
}
.v-select ::v-deep(.vs__selected + input) {
    position: absolute;
    width: 100%;
    height: 100%;
}

.multiselect ::v-deep(.multiselect__tags) {
    border-color: #c9c9c9;
    border-radius: 12px;
    padding: 0px 40px 2px 8px;
    font-size: 16px;
    display: flex;
    align-items: center;
}
.multiselect ::v-deep(.multiselect__select) {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    &::before {
        content: none;
    }
}
.multiselect ::v-deep(.multiselect__placeholder),
.multiselect ::v-deep(.multiselect__input),
.multiselect ::v-deep(.multiselect__single) {
    color: inherit;
    margin-bottom: 0;
}

.multiselect.multiselect--active ::v-deep(.multiselect__input) {
    visibility: hidden;
}

.multiselect.multiselect--active ::v-deep(.multiselect__tags-wrap > .multiselect__tag:first-child) {
    margin-top: 7px;
}
.multiselect.multiselect--active ::v-deep(.multiselect__tags-wrap > .multiselect__tag:last-child) {
    margin-bottom: 0px;
}

.waypoint-location {
    flex-grow: 2;
}
.current-location {
    background-color: #495568;
    border-radius: 50%;

    width: 28px;
    height: 28px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    cursor: pointer;

    align-self: center;
}
.section-text {
    color: var(--main-text-color);
    font-weight: 500;
    font-size: 1em;

    align-self: center;
}
.choose-type {
    font-size: 0.8em;

    &.disabled-select select {
        opacity: 0.5 !important;
    }
}
.choose-type select {
    color: #fff;
    background-color: #5c8dc8 !important;
}
.disabled {
    background-color: #bfcbd9 !important;
    cursor: not-allowed !important;
}
.area-text {
    align-self: center;
}
.response-body {
    overflow-y: hidden;
}
.shortcut.active {
    color: #4bcc87;
}

.big-wrapper {
    overflow: unset;
    max-height: 100%;
    // transition: all 2s ease-in;
}
.close-enter-active,
.close-leave-active {
    transition: all 0.4s linear;
}
.close-enter, .close-leave-to /* .fade-leave-active below version 2.1.8 */ {
    overflow: hidden;
    max-height: 0%;
}

// SAVED TRIPS
.btn.my-saved-trips {
    color: var(--main-text-color);
}
.single-trip {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .card {
        border: 0px;
        border-radius: 18px;
        background-color: #eaeaea;
        margin-left: 40px;
        flex-grow: 1;
        font-size: 14px;
    }
    .card-header {
        background-color: transparent;
        border: 0px;

        button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &.not-collapsed i.fa.fa-chevron-down,
            &.collapsed i.fa.fa-chevron-up {
                display: none;
            }
            &:focus {
                box-shadow: none;
            }
        }
    }
    .truncated-text {
        text-decoration: underline;
        color: #184172;
    }
}
.trip-waypoints {
    border-left: 1px solid #ccc;
    margin-left: 25px;
    padding-left: 16px;

    li {
        position: relative;
        line-height: 1;
        margin-bottom: 10px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -22px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #fff;
            border: 1px solid #ccc;
        }
    }
}
.saved-trips ::v-deep(.page-item .page-link) {
    color: var(--dark-blue);
}
.saved-trips ::v-deep(.page-item.active .page-link) {
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
    color: #fff;
}

// ROUTE RECAP
.recap {
    padding: 6px;
    font-size: 0.8em;
    margin: 10px 0px;
}
.recap table {
    width: 100%;
}
.recap table tr {
    border-bottom: 1px solid var(--main-gray);
}
.recap table tr:last-child {
    border-bottom: 0px;
}
.recap table tr td {
    padding: 3px 0px;
}

hr {
    margin: 0.6rem 0;
}
.route-response {
    overflow: hidden;
}

// ROUTE RESPONSE BUTTONS
.response-route-buttons {
    display: flex;
    gap: 10px;

    ::v-deep(.calculated-route-options) {
        padding: 0px;
        background-color: var(--main-gray);
        top: calc(100% + 5px) !important;
        transform: none !important;
        left: initial !important;
        right: 0px;
    }
    ::v-deep(.calculated-route-options a) {
        color: var(--dark-blue);
        font-weight: 700;
    }
    ::v-deep(.calculated-route-options a.active) {
        background-color: var(--dark-blue);
        color: #fff;
    }
}

.error-animation {
    animation: error-animation 1s ease-in-out forwards;
    border-radius: 20px;
}
@keyframes error-animation {
    0% {
        background-color: #f89090;
    }
    100% {
        background-color: #ffffff;
    }
}
</style>
