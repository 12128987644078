export default {
    id: 10101010,
    chassis: 'NC-1201-52703',
    plate: 'TRG4D3M0',
    type: 3,
    smart: 0,
    parameters: null,
    visible: true,
    category_id: null,
    lastpoint: {
        company: 1201,
        driver: {
            id: 229257,
            card: 'I1000004371380',
            name: 'JOHN',
            surname: 'DRIVER',
            card_expiration: '2028-03-09 00:00:00',
            parameters: null
        },
        truck: 287791,
        provenience: {
            id: 59,
            description: 'Golia Q-Box Realtime',
            web: 'Golia Q-Box',
            icon: 'fa fa-wifi',
            type: 'Realtime'
        },
        date: new Date(),
        lat: 45.438601,
        lng: 10.995481,
        acc: 0,
        altitude: 92.8,
        heading: 231,
        speed: 50,
        rpm_engine: 1600,
        ref_date: '2023-10-04 16:47:47',
        odometer: null,
        ignition: 1,
        gps_odometer: 376334600,
        fuel_total: 9499,
        fuel_level: null,
        tacho_speed: 0,
        telemetry: {
            gf: [],
            device_timestamp: '2023-10-04 14:47:47',
            battery_level: 100,
            external_voltage: 29052,
            gnss_status: 3,
            gsm_signal: 4
        },
        last_driver: {
            id: 229257,
            card: 'I1000004371380',
            name: 'JOHN',
            surname: 'DRIVER',
            card_expiration: '2028-03-09 00:00:00',
            parameters: null
        },
        last_driver_date: '2023-10-04 14:47:47',
        updated_at: '2023-10-04 16:47:47',
        codriver: null,
        company_name: 'Demo Golia',
        daily_distance: 100
    },
    details: {
        id: 287791,
        custom_id: 'NOME PERSONALIZZATO',
        id_main_descr: 0,
        company: 1201,
        type: 0,
        truck_id_number: null,
        truck_id_registration: null,
        type_circumference: null,
        tire_size: null,
        auth_speed: null,
        calibration_date: null,
        sector: null,
        branch: null,
        realtime_last_upload: '2023-10-04 16:48:45',
        ddd_last_upload: null,
        truck_inspection_date: null,
        profile_id: null,
        secondary_company: null,
        trailer_id: null,
        category: null
    },
    tags: [
        { id: 27, name: 'Nazionale' },
        { id: 57, name: 'Estero' }
    ],
    index: 2,
    activity_type: 'ignition'
}
