import Vue from './../common.js'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'planner.index',
            component: () =>
                import(/* webpackChunkName: "planner" */ './../views/plannerPortal/plannerPortal.vue')
        }
    ]
})

export default router
