export default class i18n {
    fallback = 'it'
    locale = null
    messages = {}
    currentDictionary = {}

    debug = false

    constructor(opt) {
        this.fallback = opt.fallback || this.fallback
        this.locale = opt.locale
        this.messages = opt.messages || {}
        this.debug = opt.debug || false
    }

    find(text) {
        let toFind = text.toLowerCase().split('.')

        let result = null

        for (var key of toFind) {
            let current = result || this.currentDictionary

            if (Object.prototype.hasOwnProperty.call(current, key)) result = current[key]
        }

        if (this.debug && !result) console.log('Translation: ' + text + ' not found')

        return result || text
    }

    loadLabels(locale) {
        if (Object.prototype.hasOwnProperty.call(this.messages, locale)) {
            this.currentDictionary = this.messages[locale]
        } else {
            this.currentDictionary = this.messages[this.fallback] || {}

            locale = this.fallback
        }

        this.locale = locale
    }

    install(Vue) {
        Vue.prototype.__ = (text) => {
            return this.find(text)
        }

        Vue.$__ = (text) => {
            return this.find(text)
        }

        Vue.prototype.$setLocale = (locale) => {
            this.loadLabels(locale)
        }

        Vue.prototype.$locale = () => this.locale

        this.loadLabels(this.locale || this.fallback)
    }
}
