<template>
    <div>
        <div v-if="value.state != 'BLOCK'" class="tiny-plafond">
            <div class="current">
                <div class="tiny-icon" style="display: flex; justify-content: center">
                    <i :class="getIconClass(value.state)"></i>
                </div>
                <div class="tiny-txt d-flex">
                    <b>{{ __(`plafonds.driver_in_${value.state}`) }}</b>
                    <span v-if="value.currentText">
                        {{ value.currentText }}
                    </span>
                </div>
            </div>
            <div class="pending" v-if="value.arrowText">
                <div class="tiny-icon" style="display: flex; justify-content: center">
                    <i class="fa-solid fa-arrow-down-long"></i>
                </div>
                <div class="tiny-txt d-flex">
                    <b>{{ __('residual_driving') }}:</b>
                    <span v-if="value.arrowText">
                        {{ value.arrowText }}
                    </span>
                </div>
            </div>
            <div class="next">
                <div class="tiny-icon" style="display: flex; justify-content: center">
                    <i :class="getIconClass(value.nextState)"></i>
                </div>
                <div class="tiny-txt">
                    {{ __('next_activity') }}:
                    <span v-if="value.nextText">
                        {{ value.nextText }}
                    </span>
                </div>
            </div>
        </div>
        <div v-else class="tiny-plafond">
            <div class="current">
                <div class="tiny-icon" style="display: flex; justify-content: center">
                    <i :class="getIconClass(value.state)"></i>
                </div>
                <div class="tiny-txt d-flex">
                    <b>{{ __(`plafonds.driver_in_${value.state}`) }}</b>
                    <span v-if="value.currentText">
                        {{ value.currentText }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PlafondDriverActivities',
    props: {
        value: Object
    },
    methods: {
        getIconClass(state) {
            if (state == 'DRIVE') return ['fa-solid', 'fa-steering-wheel', 'drive']
            if (state == 'WORK') return ['fak', 'fa-work', 'work']
            if (state == 'AVAILABILITY') return ['fak', 'fa-availability', 'available']
            if (state == 'BLOCK') return ['fak', 'fa-bar-steering', 'drive']

            return ['fa-solid', 'fa-bed', 'rest']
        }
    }
}
</script>

<style scoped>
.tiny-plafond {
    display: flex;
    flex-direction: column;

    /* align-items: center; */
    /* background-color: #d7dce8; */
    /* border-radius: 10px; */
    /* height: 100%; */
    padding: 5px;
    gap: 10px;
}
.tiny-plafond i {
    font-size: 1.1rem;
}
.current,
.pending,
.next {
    /* display: flex;
    justify-content: center;
    align-content: center;
    flex-grow: 1;
    background-color: #d7dce8; */
    display: inline-flex;
    height: 33px;
    padding: 6px 20px 6px 20px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: #d1d7e4;
    gap: 10px;
}

.tiny-txt,
.tiny-icon {
    /* margin-bottom: 2px; */
}
.tiny-icon {
    width: 25px;
}
.tiny-value {
    font-weight: 500;
}
.tiny-txt {
    display: flex;
    gap: 5px;
    padding-bottom: 2px;
}

.rest {
    color: #87cb7e;
}
.available {
    color: #96cee9;
}
.work {
    color: #fc9e42;
}
.drive {
    color: #e83744;
}
</style>
