// Alcuni clienti (2 al momento) hanno solo il Planner, e quindi questo file si occupa di buildare solo quello.
import Vue from './common.js'
import App from './App.vue'
import { abilitiesPlugin } from '@casl/vue'

import AuthProcess from './plugins/AuthProcess.js'

import planner from './routes/planner.js'

import store from './store/index.js'

const router = (() => {
    return planner
})()

Vue.use(abilitiesPlugin, store.getters.ability)
Vue.use(AuthProcess, store, router)

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')
