<template>
    <l-marker
        :visible="truck.visible"
        :lat-lng="[truck.lastpoint.lat, truck.lastpoint.lng]"
        @click="$emit('click', truck)"
    >
        <l-icon :icon-anchor="anchorSize" :icon-size="iconSize" class-name="position-relative">
            <svg
                :class="{ bounce: bounce, focused: truck.on_focus }"
                id="Goccia"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-19 -3 159.5 190.4"
            >
                <path
                    id="Traccia_goccia"
                    data-name="Traccia goccia"
                    d="M164,84.8c0,33-59.7,109.2-59.7,109.2S44.5,117.8,44.5,84.8a59.8,59.8,0,0,1,119.5,0Z"
                    transform="translate(-44.5 -12.6)"
                    fill="#fff"
                />
                <path
                    id="Base_goccia"
                    data-name="Base goccia"
                    d="M157.4,87.6c0,29.3-53.1,97-53.1,97s-53.1-67.7-53.1-97a53.1,53.1,0,0,1,106.2,0Z"
                    transform="translate(-44.5 -12.6)"
                    :fill="`var(${vehicle_color})`"
                    style="filter: brightness(1.2)"
                />
                <path
                    id="Mezza_goccia_riflesso"
                    data-name="Mezza goccia riflesso"
                    d="M157.4,87.6a53.1,53.1,0,0,0-53.1-53.1V184.6S157.4,116.9,157.4,87.6Z"
                    transform="translate(-44.5 -12.6)"
                    :fill="`var(${vehicle_color})`"
                    style="filter: brightness(0.9)"
                />
                <g
                    class="rotate"
                    fill="div-rotate"
                    :style="{
                        transform: direction,
                        'transform-origin': '37.7% 39%',
                        '-webkit-transform-Origin': '37.7% 39%',
                        '-moz-transform-Origin': '37.7% 39%',
                        '-ms-transform-Origin': '37.7% 39%',
                        '-o-transform-Origin': '37.7% 39%'
                    }"
                >
                    <path
                        v-if="vehicleAlertsList.length > 0"
                        id="Freccia_direzionale"
                        data-name="Freccia direzionale"
                        d="M150.1,86.4a45.9,45.9,0,0,0-30.2-43.1L104.3,12.6,88.7,43.3a45.8,45.8,0,1,0,61.4,43.1Z"
                        transform="translate(-44.5 -12.6)"
                        fill="var(--danger)"
                    />
                    <path
                        v-else
                        id="Freccia_direzionale"
                        data-name="Freccia direzionale"
                        d="M150.1,86.4a45.9,45.9,0,0,0-30.2-43.1L104.3,12.6,88.7,43.3a45.8,45.8,0,1,0,61.4,43.1Z"
                        transform="translate(-44.5 -12.6)"
                        :fill="`var(${vehicle_color})`"
                        style="filter: brightness(0.6)"
                        :class="vehicle_status"
                    />
                </g>
                <text
                    v-if="vehicleAlertsList.length > 0"
                    x="35"
                    y="90"
                    style="font-family: 'Font Awesome Kit', 'FontAwesome'; font-size: 52px"
                    fill="#fff"
                    v-html="'&#xF071;'"
                ></text>
                <text
                    v-else
                    x="28"
                    y="92"
                    style="font-family: 'Font Awesome Kit', 'FontAwesome'; font-size: 52px"
                    fill="#fff"
                    v-html="
                        truck.category && truck.category.fa
                            ? JSON.parse(truck.category.fa).unicode
                            : '&#xe00d;'
                    "
                ></text>
            </svg>
            <div
                class="plate d-flex flex-column flex-wrap"
                :class="{ focused: truck.on_focus }"
                :style="
                    config.vehicleMarkerFontColor
                        ? '--text-color: ' + config.vehicleMarkerFontColor
                        : ''
                "
            >
                <span
                    :style="markerStyle"
                    :class="{ 'with-additional-data': vehicleAdditionalData }"
                    v-if="config?.vehicleIdentification && config?.copyIdentificationToMarker"
                    >{{ vehicleIdentification | truncate(7) }}</span
                >
                <span
                    :style="markerStyle"
                    :class="{ 'with-additional-data': vehicleAdditionalData }"
                    v-else
                    >{{ (truck.details.custom_id || truck.plate) | truncate(7) }}</span
                >

                <span
                    v-if="vehicleAdditionalData"
                    :style="markerAdditionalInfoStyle"
                    style="border-top-left-radius: 0px; border-top-right-radius: 0px"
                    >{{ vehicleAdditionalData }}</span
                >
            </div>
        </l-icon>
    </l-marker>
</template>

<script>
import { toRefs } from 'vue'
import { LMarker, LIcon } from 'vue2-leaflet'
import { useVehicleStatus } from '@/composables/useVehicleStatus'
import { useVehicleAlerts } from '@/composables/useVehicleAlerts'
import { mapState } from 'vuex'

export default {
    name: 'LTruckMarker',
    props: ['truck'],
    setup(props) {
        const { truck } = toRefs(props)
        const { vehicle_status, vehicle_color } = useVehicleStatus(truck)
        const { alerts } = useVehicleAlerts(truck)

        return {
            vehicle_status,
            vehicle_color,
            alerts
        }
    },
    components: {
        LMarker,
        LIcon
    },
    data() {
        return {
            bounce: false
        }
    },
    watch: {
        'truck.lastpoint': function (lastpoint) {
            if (this.bounce) this.bounce = false

            if (this.truck.on_focus) this.$emit('moving', lastpoint.lat, lastpoint.lng)
        },
        'truck.on_focus': function (active) {
            this.bounce = !!active
        }
    },
    computed: {
        iconSize() {
            let markerSize = this.config?.vehicleMarkerSize || [52, 66]
            return markerSize
        },
        anchorSize() {
            let markerSize = this.config?.vehicleMarkerSize || [52, 66]
            return [markerSize[0] / 2, markerSize[1]]
        },
        markerStyle() {
            let style = ''

            if (this.config.vehicleMarkerFontSize)
                style += '--text-size: ' + this.config.vehicleMarkerFontSize + ';'

            if (this.config.vehicleMarkerBackgroundColor)
                style += '--bg-color: ' + this.vehicleMarkerBackgroundColor.rgb + ';'

            // this.config.vehicleMarkerOpacity comes with a value from 0 to 100 and consists to the negative value of the opacity
            if (this.config.vehicleMarkerOpacity)
                style += '--opacity: ' + (100 - this.config.vehicleMarkerOpacity) / 100 + ';'

            return style
        },
        markerAdditionalInfoStyle() {
            let style = ''
            let backgroundColor =
                this.vehicleAdditionalInfoBackgroundColor || this.vehicleMarkerBackgroundColor
            let fontColor =
                this.config.vehicleAdditionalInfoFontColor || this.config.vehicleMarkerFontColor
            let opacity =
                this.config.vehicleAdditionalInfoOpacity || this.config.vehicleMarkerOpacity

            if (backgroundColor) style += '--bg-color: ' + backgroundColor.rgb + ';'

            if (opacity) style += '--opacity: ' + (100 - opacity) / 100 + ';'

            if (fontColor) style += '--text-color: ' + fontColor + ';'

            return style
        },
        vehicleMarkerBackgroundColor() {
            if (!this.config?.vehicleMarkerBackgroundColor) return null

            let color = this.config?.vehicleMarkerBackgroundColor
            let r = parseInt(color.substring(1, 3), 16)
            let g = parseInt(color.substring(3, 5), 16)
            let b = parseInt(color.substring(5, 7), 16)

            return {
                hex: color,
                rgb: `${r}, ${g}, ${b}`
            }
        },
        vehicleAdditionalInfoBackgroundColor() {
            if (!this.config?.vehicleAdditionalInfoBackgroundColor) return null

            let color = this.config?.vehicleAdditionalInfoBackgroundColor
            let r = parseInt(color.substring(1, 3), 16)
            let g = parseInt(color.substring(3, 5), 16)
            let b = parseInt(color.substring(5, 7), 16)

            return {
                hex: color,
                rgb: `${r}, ${g}, ${b}`
            }
        },
        truckSpeed() {
            return this.truck.lastpoint.tacho_speed || this.truck.lastpoint.speed
        },
        vehicleAlertsList() {
            return Object.values(this.alerts) || []
        },
        direction() {
            return `rotate(${this.truck.lastpoint.heading}deg)`
        },
        vehicleIdentification() {
            let identification = this.truck?.details?.custom_id || this.truck.plate

            if (this.config && this.config.vehicleIdentification) {
                if (this.config.vehicleIdentification == 'customId') {
                    identification = this.truck.details.custom_id || this.truck.plate
                } else if (this.config.vehicleIdentification == 'driver') {
                    let validDriver = ''

                    if (this.truck.lastpoint.driver && this.truck.lastpoint.driver.id > 1) {
                        validDriver = this.truck.lastpoint.driver
                    } else if (
                        this.truck.lastpoint.codriver &&
                        this.truck.lastpoint.codriver.id > 1
                    ) {
                        validDriver = this.truck.lastpoint.codriver
                    } else {
                        if (this.truck.type == 3)
                            validDriver = { name: this.truck.plate, surname: null }
                        else validDriver = { name: null, surname: null }
                    }

                    if (!validDriver.name && !validDriver.surname) {
                        identification = '- ' + this.__('no_card') + ' -'
                    } else {
                        identification =
                            this.$options.filters.truncate(validDriver.name || '--', 10, '...') +
                            ' ' +
                            this.$options.filters.truncate(validDriver.surname || '', 10, '...')
                    }
                } else {
                    identification = this.truck.plate
                }
            } else {
                identification = this.truck.details.custom_id || this.truck.plate
            }

            return identification
        },
        vehicleAdditionalData() {
            if (this.config && this.config.vehicleMarkerAdditionalData) {
                let additionalData = this.config.vehicleMarkerAdditionalData
                let data = ''

                if (additionalData == 'speed') {
                    data =
                        (this.truck.lastpoint.tacho_speed || this.truck.lastpoint.speed) + ' Km/h'
                } else if (additionalData == 'altitude') {
                    data = this.truck.lastpoint.altitude + ' m.s.l.m'
                } else if (additionalData == 'rpm_engine') {
                    data = this.truck.lastpoint.rpm_engine + ' RPM'
                }

                return data
            } else {
                return null
            }
        },
        ...mapState({
            config: (state) => state.geoFleet.config
        })
    }
}
</script>

<style scoped>
.bounce {
    animation: bounce 2.5s ease 1;
}
@keyframes bounce {
    0%,
    15%,
    35%,
    55%,
    75%,
    100% {
        transform: translateY(0);
    }
    10% {
        transform: translateY(-25px);
    }
    30% {
        transform: translateY(-25px);
    }
    50% {
        transform: translateY(-25px);
    }
    70% {
        transform: translateY(-25px);
    }
    90% {
        transform: translateY(-25px);
    }
}
.plate {
    --text-color: var(--main-text-color);
    display: flex;
    justify-content: center;
    font-size: 0.7rem;
    color: var(--text-color);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.plate span {
    --text-size: 10px;
    --bg-color: var(--main-bg-color--rgb);
    padding: 4px 8px;
    background: rgba(var(--bg-color), var(--opacity, 0.6));
    font-weight: 700;
    border-radius: 10px;
    font-size: var(--text-size);
    white-space: nowrap;
    color: var(--text-color, #333);

    &.with-additional-data {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}
</style>
