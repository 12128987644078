export default {
    //General labels
    open: 'Open',
    close: 'Close',
    apply: 'Apply',
    cancel: 'Cancel',
    search: 'Search',
    cancel_search: 'Cancel search',
    edit_search: 'Edit search',
    erase: 'Erase',
    back_to_results: 'Back to results',
    save: 'Save',
    retry: 'Try again',
    click_here: 'Click here',
    copy: 'Copy',
    copied: 'Copied',
    copied_coordinates: 'Coordinates copied',
    select: 'Select',
    selected: 'Selected',
    selected_plural: 'Selected',
    deselect: 'Deselect',
    select_multi_vehicles: 'Select one or more vehicles',
    include: 'Include',
    exclude: 'Exclude',
    balanced: 'Balanced',
    input: 'Entrance',
    output: 'Exit',
    verified: 'Verified',
    empty_search: 'No results founded',
    read_more: 'Read more',
    details: 'Details',
    actual: 'Actual',
    residual_time: 'Residual time',
    next: 'Next',
    previous: 'Previous',
    menu_open: 'Open menu',
    hide: 'Hide',
    show: 'Show',
    none: 'None',
    none_alt: 'None',
    all: 'All',
    all_alt: 'All',
    default: 'Default',
    settings: 'Settings',
    reset_settings: 'Reset settings',
    reset_settings_success: 'Settings reset successfully',
    multiselect_default_value: {
        singular: 'Value selected: ',
        plural: 'Values selected: '
    },

    available: 'Available',
    unavailable: 'Unavailable',
    not_available: 'Unavailable',
    not_available_plural: 'Not available',
    from: 'from',
    to: 'to',
    of: 'Of',
    yes: 'Yes',
    no: 'No',

    minutes: 'Minutes',
    hours: 'Hours',
    hour: 'Hour',
    days: 'Days',
    day: 'Day',
    months: 'Months',
    month: 'Month',
    years: 'Years',
    year: 'Year',
    days_names: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    days_names_short: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    months_names: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'Septemper',
        'October',
        'November',
        'December'
    ],
    months_names_short: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dic'
    ],
    areas: 'Areas',
    area: 'Area',
    time: 'Time (min)',
    time_label: 'Time',
    cost: 'Cost',
    toll_cost: 'Toll cost',
    km_cost: 'Cost per km',
    hourly_cost: 'Hourly cost',
    working_cost: 'Cost per man total',
    total_cost: 'Total cost',

    expire_date: 'Expiration date',
    start_date: 'Start date',
    recap: 'Summary',
    start: 'Start',
    starting_point: 'Departure',
    end: 'End',
    finish_point: 'Arrival',
    duration: 'Duration',
    distance: 'Distance',
    daily_distance: 'Km traveled per day',
    distance_total: 'Total distance',
    gps_distance: 'Distance (GPS)',
    distance_gps: 'Km traveled per day (GPS)',
    pause: 'Break time',
    segment: 'Route',
    start_time: 'Start time',
    end_time: 'End time',
    service_time: 'Service time',
    waypoint_load: 'Load on board',
    state: 'State',
    violated_plafond: 'Plafond violation',
    traffic_delay: 'Traffic delay',
    company: 'Company',
    address: 'Address',
    position: 'Position',
    route: 'Route',
    filter_date: 'Filter by date',

    warning: 'Warning',
    no_warning: 'No warnings',
    alerts: 'Alerts',
    trucks_with_alerts: 'Trucks with alerts',
    bluetooth_devices: 'Bluetooth devices',
    no_bluetooth_devices: 'No bluetooth devices found',
    connected: 'Connected',
    disconnected: 'Disconnected',
    bluetooth_types: {
        temperature_probe: 'Temperature probe',
        door_probe: 'Door and temperature probe'
    },

    back_to_home: 'Back to home',

    //Actions
    cerca: 'Search',
    search_vehicle: 'Search vehicle',
    filter: 'Filter',
    filter_label: 'Filter',
    filters_label: 'Filters',
    filter_by: 'Filter by',
    operations: 'Operations',
    filter_vehicles: 'Filter vehicles',
    filter_applied: 'filter applied',
    filter_canceled: 'Filter canceled successfully',
    order_by: 'Order by',
    ascending: 'Ascending',
    descending: 'Descending',
    order: 'Order',
    value: 'Value',
    select_filter: 'Select filter',

    sort_vehicles: 'Order vehicles',
    sort_for_alphanumeric: 'Alphabetical',
    sort_for_lastpoint: 'Chronological',
    sort_for_company: 'Company',
    sort_for_activity: 'Activities',
    company_select: 'Select company',
    no_companies_founded: 'No company found',
    multicompany: 'Filter by company',
    no_vehicles_founded: 'No vehicle found',
    print: 'Print',
    export: 'Export',
    print_recap: 'Summary print',
    select_date: 'Select a date',
    add: 'add',
    add_new: 'Add new',
    delete: 'Delete',
    edit: 'Edit',
    options: 'Options',
    login: 'Log in',
    logout: 'Log out',
    logout_confirm: 'Are you sure you want to log out?',
    login_button: 'Login',
    connect: 'Connect',
    logged: 'Connected',
    manage: 'Manage',
    loading: 'Loading',
    loading_timeout: 'Network error, please reload the page or try again later',
    go_back: 'Go back',
    select_coordinates: 'Select coordinates',
    other: 'Other',

    // Settings
    options_general: 'General',
    options_language: 'Language',
    change_interface_language: 'Change interface language',
    change_infringment_language: 'Change infringement language',
    change_timezone: 'Change timezone',
    save_language_settings: 'Save language settings',

    vehicle_list_options: 'Vehicle list',
    select_view: 'Select view',
    select_fields_placeholder: 'Select fields to view',
    selected_field: 'Selected field',
    selected_fields: 'Selected fields',
    list: 'List',
    table: 'Table',

    view_options: 'View options',
    fluid_table: 'Dynamic width',
    fluid_table_descr: 'Make the table width fluid according to the selected fields',
    vehicle_fields_list: 'What information do you want to appear for each vehicle? (Max 3)',
    vehicle_fields_list_warning:
        'Attention: the field will not be displayed if it is not available for the vehicle',
    vehicle_identification: 'How do you want the vehicle to be identified in the main list?',
    apply_to_marker: 'Also applies this setting to vehicles on the map',

    options_map: 'Map options',
    vehicles_clustering: 'Vehicle grouping',
    vehicles_clustering_warning: 'Updating this setting requires reloading the page',
    options_marker: 'Marker options',
    size: 'Size',
    font_size: 'Text size',
    font_color: 'Text color',
    background_color: 'Background color',
    opacity: 'Opacity',
    color: 'Color',
    small: 'Small',
    medium: 'Medium',
    large: 'Large',
    additional_info: 'Additional information',

    analysis_options: 'Route history',
    options_chart: 'Chart options',
    options_timeline: 'Timeline options',
    hide_timeline: 'Hide timeline',

    // Others
    map: 'Map',
    silica: 'Silica',
    google: 'Google',
    google_satellite: 'Google Satellite',
    sandbox: 'Sandbox',
    silkysand: 'Silkysand',
    gravelpit: 'Gravelpit',
    open_street_maps: 'Open Street Maps',
    arcgis_satellite: 'ArcGIS Satellite',
    traffic: 'Traffic',
    heavy_vehicles_ban: 'Heavy vehicles prohibitions',

    //Drivers
    driver: 'Driver',
    no_driver: 'No driver',
    drivers: 'Drivers',
    driver_card: 'Driver card',
    name: 'Name',
    surname: 'Surname',
    activity_type: 'Activity type',
    activity: 'Activity',
    no_activity: 'No activity',
    driver_at_rest: 'Driver at rest',
    driver_available: 'Available driver',
    driver_at_work: 'Driver at work',
    driver_driving: 'Driver driving',
    inactive_vehicle: 'Inactive vehicle',
    last_driver_detected: 'Last driver detected',
    rest: 'Rest',
    work: 'Work',
    driving: 'Guide',
    availability: 'Availability',
    codriving: 'Codriving',
    codriver: 'Codriver',
    break: 'Session break',
    daily_rest: 'Daily rest',
    violation: 'Violation',
    to_begin_within: 'To begin within',
    to_end_within: 'To end within',
    after_total_time_travel: 'After a travel time total of',
    after_total_distance_travel: 'After a travel distance total of',
    residual_commitment_type: 'Residual commitment type',
    residual_commitment: 'Residual commitment',
    commitment_hours: 'Commitment hours',
    commitment_hours_description:
        'Find all vehicles whose drivers have a higher residual commitment than indicated',
    driver_plafond: 'Driver plafond',
    residual_driving: 'Residual driving',
    residual_bonuses: 'Residual bonuses',
    consider_bonuses: 'The time is calculated considering the remaining bonuses',
    current_activity: 'Current activity',
    next_activity: 'Next activity',
    view_plafond_detail: 'View plafond detail',
    card: 'Card',

    //Sections
    shared_links: 'Shared link',
    gf: 'POI detected',
    analyze: 'Analyze',
    plan: 'Plan',

    //Vehicles
    vehicle: 'Vehicle',
    vehicles: 'Vehicles',
    vehicle_info: 'Vehicle information',
    vehicle_type: 'Vehicle type',
    vehicle_body: 'Vehicle body',
    brand: 'Brand',
    model: 'Model',
    no_card: 'Card not inserted',
    no_card_driving: 'Driving without card',
    speed: 'Speed',
    level: 'Level',
    battery: 'Battery',
    low_battery_level: 'Low battery level',
    fuel: 'Fuel',
    fuel_level: 'Fuel level',
    low_fuel_level: 'Low fuel level',
    consumption: 'Consumption',
    consumptions: 'Consumptions',
    fuel_consumption: 'Fuel consumption',
    fuel_avg_consumption: 'Average daily fuel consumption',
    altitude: 'Altitude',
    weight: 'Weight',
    vehicle_weight: 'Weight (t)',
    vehicle_length: 'Length (m)',
    vehicle_width: 'Width (m)',
    vehicle_height: 'Height (m)',
    odometer: 'Odometer',
    odometer_gps: 'Odometer (GPS)',
    last_point: 'Last point',
    moving: 'Moving',
    stopped: 'Stopped',
    engine_on: 'Engine on',
    ignition: 'Engine',
    engine_off: 'Engine off',
    engine_on_off: 'Engine on/off',
    ignition_on: 'Ignition on',
    ignition_off: 'Ignition off',
    device: 'Device',
    plate: 'Plate',
    chassis: 'Chassis',
    status: 'Status',
    custom_id: 'Custom Id',
    heading: 'Direction',
    rpm_engine: 'RPM Engine',

    device_timestamp: 'Timestamp',
    info: 'Info',
    solar_day_report: 'Solar day report',
    telemetry: 'Telemetry',
    parameters: 'Parameters',
    engine_coolant_temp: 'Engine temperature',
    engine_rpm: 'Engine RPM',
    dallas_temp_1: 'Temperature 1',
    dallas_temp_2: 'Temperature 2',
    dallas_temp_id_1: 'Temperature ID 1',
    dallas_temp_id_2: 'Temperature ID 2',
    digital_input_1: 'Digital input 1',
    digital_input_2: 'Digital input 2',
    battery_level: 'Battery level',
    adblue_liters: 'Adblue liters',
    adblue_level: 'Adblue level',
    gnss_status: 'GNSS status',
    gnss_status_values: {
        0: 'Off',
        1: 'On, without GPS',
        2: 'On, without satellites',
        3: 'On, with satellites',
        4: 'On, with sleep mode',
        5: 'Protection mode'
    },
    gnss_off: 'GNSS off',
    gnss_on_fix: 'GNSS on, with satellites',
    gnss_on_without_fix: 'GNSS on, without satellites',
    gnss_on_sleep: 'GNSS on, with sleep mode',
    gnss_on_protection: 'GNSS on, protection mode',
    gsm_signal: 'GSM signal',
    tacho_source: 'Tachograph source',
    external_voltage: 'Power supply',
    tacho_overspeed: 'Tachograph overspeed',
    no_points_title: 'Last activity for more than 24 hours',
    no_points: 'This vehicle has not sent points for more than 24 hours',
    clutch: 'Clutch',
    clutch_switch: 'Clutch switch',
    pedal_released: 'Pedal released',
    pedal_pressed: 'Pedal pressed',
    data_mode: 'Data mode',
    home_stop: 'Home stop',
    home_moving: 'Home moving',
    roaming_stop: 'Roaming stop',
    roaming_moving: 'Roaming moving',
    unknown_stop: 'Unknown stop',
    unknown_moving: 'Unknown moving',
    adblue_off: 'Adblue off',
    adblue_red: 'Adblue red',
    adblue_yellow: 'Adblue yellow',
    adblue_info: 'Adblue info',
    charger_notconnected: 'Charger not connected',
    charger_connected: 'Charger connected',
    tacho_unknown: 'Unknown',
    tacho_kline: 'K-Line',
    tacho_allcan: 'BUS-CAN',
    tacho_can: 'BUS-CAN',
    tacho_fms: 'FMS',
    vehicle_not_found: 'The vehicle you are trying to access is not available',
    vehicle_with_lock_commands: 'Vehicle with lock commands',
    lock: 'Lock',
    unlock: 'Unlock',
    send: 'Send',
    command_sent: 'Command sent',
    turn_on: 'Turn on',
    turned_on: 'Turned on',
    turn_off: 'Turn off',
    turned_off: 'Turned off',
    confirm_send_command: 'Do you confirm that you want to send the command?',

    temperature: {
        title: 'Temperature probes',
        probe: ['Probe 1', 'Probe 2', 'Probe 3', 'Probe 4', 'Probe 5', 'Probe 6']
    },

    no_power_supply: 'No power supply',
    no_gps: 'No GPS',
    tacho_clock_error: 'Tachograph clock error',
    device_timestamp_error: 'Device timestamp error',

    select_color_mode: 'Select color mode',
    light_mode: 'Light mode',
    dark_mode: 'Dark mode',
    color_blind_mode: 'Color blind mode',
    color_blind_dark_mode: 'Color blind dark mode',

    //Geofleet
    geofleet: {
        mainpanel: {
            pinned_vehicles: 'Pinned vehicles',
            search_trucks_title: 'Search vehicles',
            search_trucks_tags_title: 'Binded tags',
            search_trucks_status_title: 'Vehicle activity',
            search_trucks_driver_status_title: 'Driver activity',
            search_near_trucks_title: 'Find nearby vehicles'
        },
        detailspanel: {
            bind_vehicle: 'Associate vehicle',
            add_new_tag: 'Add as a new tag',
            select_new_tag: 'Select or search a tag',
            binded_tags: 'Associate tags',
            binded_tag: 'Associate tag'
        },
        analyzer: {
            vehicle_activity_analisys: 'Vehicle activity analysis',
            timeline_separator: 'Group by'
        },
        search_resources: {
            title: 'Search resources',
            description: 'Find gas stations, parkings and reachable areas near to this vehicle.'
        }
    },

    //Planner
    planner: {
        user_config: 'User configuration',
        no_user_config: 'No user configuration',
        freight_access: 'Freight access',
        vehicle_actual_position: 'Vehicle current location',
        plan_route: 'Plan route',
        select_on_map: 'Select a point on the map',
        select_on_map_option: 'Select on the map',
        fill_route_info: 'Fill out the information of the route',
        fill_resources: 'Select the point to add as a stop',
        fill_waypoints: 'Select the stages',
        load_breaks: 'Load breaks',
        savedroutes: {
            my_routes: 'My routes',
            saved_routes: 'Saved Routes',
            no_saved_routes: "You haven't saved any route yet",
            success_saved_route: 'Your route was successfully saved',
            error_saved_route: 'A mistake occurred during the saving of the route',
            success_deleted_route: 'Your route has been successfully eliminated',
            error_deleted_route: 'An error occurred during the elimination of the route'
        },
        resources: {
            area_search: 'Search within the radius of',
            station_services: 'Inclusive of these services',
            parking_services: 'Parking services',
            select_rating: 'Select the rating',
            select_security: 'Select the security',
            empty_search: 'Research has not found any element to show',
            delivery_nation: 'Delivery nation',
            fai_featured: 'Lumesia affiliated',
            self_service: 'Self service',
            served: 'Served',
            total_parking_spots: 'Total seats',
            rating: 'Installment',
            reviews: 'Reviews',
            security: 'Safety',
            price_per_night: 'Price per night'
        },
        emissions_labels: 'Emissions',
        emissions: {
            fuelconsumption: 'Fuel consumption',
            hydrocarbons: 'Hydrocarbons',
            methane: 'Methane',
            hydrocarbonsexceptmethane: 'Hydrocarbons except methane',
            carbonmonoxide: 'Carbon monoxide',
            carbondioxide: 'Carbon dioxide',
            fossilcarbondioxide: 'Fossil carbon dioxide',
            sulphurdioxide: 'Sulphur dioxide',
            nitrogenoxides: 'Nitrogen oxides',
            nitrogendioxide: 'Nitrogen dioxide',
            nitrousoxide: 'Nitrous oxide',
            ammonia: 'Ammonia',
            benzene: 'Benzene',
            particles: 'Particles',
            numberofparticles: 'Number of particles'
        },
        plafond_configuration: {
            title: 'Plafond configuration',
            driving_time_regulation: 'Driving time',
            last_time_the_driver_worked: "Driver's last activity",
            eu_driving_time_regulation_for_single_day: 'Single day',
            eu_driving_time_regulation_for_single_long_day: 'Single day (with bonus)',
            eu_driving_time_regulation_for_multiple_day: 'Multiple day',
            eu_driving_time_regulation_for_team_and_multiple_day: 'Multiple day (with codriver)',
            accumulated_driving_time_since_lastbreak: 'Driving time since last break',
            accumulated_driving_time_since_lastdailyrest: 'Driving time since last daily rest',
            accumulated_travel_time_since_lastdailyrest: 'Travel time since last daily rest'
        },
        set_route: 'Set up route',
        select_profile: 'Select Profile',
        select_co2_class: 'Select emissions class',
        class: 'Class',
        billed_roads: 'Toll roads',
        waypointevent: 'Stage',
        waypoints_label: 'Stages',
        add_waypoint: 'Add stage',
        delete_waypoint: 'Delete stage',
        select_waypoint: 'Select stage',
        select_resource: 'Select the point to add as a stop',
        last_waypoint: 'Last stage',
        waypoints_check: 'There are stages to be completed',
        waypoints_required: 'At least 2 stages are needed',
        countryevent: 'State',
        tollevent: 'Toll station',
        event: 'Event',
        alpega: 'Alpega',
        alpega_login: 'Alpega login',
        alpega_searches: 'Alpega searches',
        timocom: 'Timocom',
        pickup: 'Collection',
        delivery: 'Delivery',
        extimated_cost: 'Estimated cost',
        calculate_route: 'Calculate route',
        calculate_isocrona: 'Calculate isocrona',
        complete_step_2: 'Complete the step 2',
        reset_route: 'Reset route',
        route_error: 'An error occurred during the calculation of the route',
        edit_route: 'Edit route',
        save_route: 'Save route',
        delete_route: 'Delete route',
        route_name: 'Route name',
        override_route: 'Override route',
        save_as_new_route: 'Save as new route',
        calculate_route_for: 'Calculate route for',
        ptvexport: 'Export in PTV Navigator',
        isocrona: 'Reachable areas',
        reset_isocrona: 'Reachable areas reset',
        search_location: 'Search locality',
        search_station: 'Search distributor',
        search_parking: 'Search parking',
        search_poi: 'Search POI',
        include_plafond: 'Include drivers plafond in the route calculation',
        include_plafond_near_vehicles:
            'Find vehicles with drivers having a sufficient residual commitment to reach the searched position',
        violated_plafond: 'The route violates the drivers plafond'
    },

    //Shared Links
    sharedlinks: {
        back_to_links: 'Back to the links',
        create_link: 'Create links',
        update_link: 'Update Link',
        edit_link: 'Edit link',
        update_link_expire_date: 'Update expiration date',
        delete_link: 'Delete Link',
        delete_link_confirm: 'Delete the selected link?',
        all_your_shared_links: 'All your shared links',
        no_shared_links: "You haven't shared any vehicle yet",
        vehicles_link: 'Links for vehicles',
        select_share_vehicles: 'Select vehicles to share',
        select_start_expire_date: 'Select start and expiry date',
        created_link_error: 'Error when creating the link',
        created_link_success: 'Link successfully created',
        updated_link_error: 'Error when updating the link',
        updated_link_success: 'Link updated with success',
        service_description: 'Share this link to show the tracking of selected vehicles',
        link_preview: 'Preview of the link',
        share_single_vehicle: 'Share the location of this vehicle',
        track_vehicle_position: 'Trace the position of the selected vehicles',
        start_date_warning: 'The start date cannot be changed',
        start_date_condition: 'If you want to change the start date, you must create a new link',
        expire_date_description:
            'Indicate an expiry date, beyond which this link will no longer be available',
        start_expire_date_description:
            'Indicate an initial date, starting from which this link will be available, and an expiry date, beyond which this link will no longer be available'
    },

    viewsharedlink: {
        invalid_date_range: 'The range of dates you have selected is not valid for this link',
        too_early_link: 'The link you are trying to access is not yet available',
        too_late_link: 'The link you are trying to access no longer exists',
        show_route: 'Show route'
    },

    tags: {
        manage_tags: 'Manage tags',
        add_tag: 'Add tag',
        add_tag_placeholder: 'Insert tag name',
        add_tag_error: 'Error during tag creation',
        add_tag_success: 'Tag created successfully',
        delete_tag: 'Delete tag',
        delete_tag_confirm: 'Delete the selected tag?',
        delete_tag_error: 'Error during tag deletion',
        delete_tag_success: 'Tag deleted successfully',
        update_tag: 'Update tag',
        update_tag_error: 'Error during tag update',
        update_tag_success: 'Tag updated successfully',
        all_your_tags: 'All your tags',
        no_tags: "You haven't created any tags yet",
        tag_name: 'Tag name'
    },

    manage_poi: {
        title: 'Manage POI',
        your_pois: 'Your POI',
        add_poi: 'Add new POI',
        no_pois: "You haven't created any POI yet",
        fields: {
            name: 'Name',
            type: 'Type',
            color: 'Color',
            note: 'Notes',
            address: 'Address',
            latitude: 'Latitude',
            longitude: 'Longitude',
            radius: 'Radius',
            radius_description: 'To enable geofencing set a value greater than zero'
        },
        types_poi: {
            all: 'All',
            headquarter: 'Headquarter',
            truck_washes: 'Truck Washes',
            blacklist: 'Blacklist',
            end_of_the_line: 'End Of the line',
            customers: 'Customers',
            stops: 'Stops',
            suppliers: 'Suppliers',
            interports: 'Interports',
            markets: 'Markets',
            large_square: 'Large Square',
            poi_distributors: 'POI Distributors',
            poi_workshops: 'POI Workshops',
            harbors: 'Harbors',
            restaurants: 'Restaurants',
            stations: 'Stations'
        },
        messages: {
            add_poi_error: 'Error during POI creation',
            add_poi_success: 'POI created successfully',
            update_poi_error: 'Error during POI update',
            update_poi_success: 'POI updated successfully',
            delete_poi_error: 'Error during POI deletion',
            delete_poi_success: 'POI deleted successfully'
        }
    },

    vehicles_cross: {
        title: 'Vehicles cross',
        fields: {
            address: 'Address',
            radius: 'Radius',
            time_interval: 'Time interval',
            time_interval_description: 'The maximum time interval is 24 hours'
        },
        messages: {
            max_time_interval: 'The maximum time interval is 24 hours',
            error_searching: 'An error occurred while searching for vehicles',
            searching: 'Searching...',
            long_loading: 'The search could be lasting few minutes, please wait...',
            no_vehicles_cross: 'No vehicles cross found'
        }
    },

    plafonds: {
        driver_in_drive: 'You are driving by',
        driver_in_rest: 'You are resting from',
        driver_in_work: 'You are working since',
        driver_in_available: 'You are available since',
        driver_in_availability: 'You have been available since',
        current_activity: 'Current activity',
        mini_plafond: 'Mini-Plafond',
        last_update: 'Last update',
        panel_title: 'Plafond Panel',
        residual_commitment: 'Residual commitment',
        daily_driving: 'Daily driving',
        weekly_guide: 'Weekly guide',
        biweekly_guide: 'Bi-weekly guide',
        last_rest: 'Last weekly rest taken',
        minimal_duration_next: 'Minimum duration next',
        top_start_until: 'To start by',
        reduced_weekly_rest: 'Reduced weekly rests to compensate',
        time_to_compensate: 'Time to compensate',
        time_to_compensate_until: 'To be compensated by',
        footer_message: '* the time is calculated considering the residual bonuses'
    },
    error_pages: {
        error_403: {
            title: 'Error 403',
            description:
                'Dear Customer, we would like to inform you that your user account is currently not configured to access this service. To receive information on this service',
            retry_login: 'Retry login',
            contact_us: 'Contact us'
        }
    },

    // PTV themes errors
    ptv: {
        maxpermittedweight: 'Maximum weight',
        minpermittedweight: 'Minimum weight',
        zonename: 'Zone',
        unauthorizedtype: 'Unauthorized Code',
        maxemissionstandardeu: 'Emission class',
        fueltype: 'Fuel type',
        loadtype: 'Load type',
        opening: 'Passage',
        maxheight: 'Maximum height',
        maxweight: 'Maximum weight',
        maxwidth: 'Maximum width',
        maxlength: 'Maximum length',
        absolutespeed: 'Speed',
        hazardoustowaters: 'Forbidden hazardous (to water) goods',
        hazardousgoods: 'Forbidden hazardous goods',
        combustibles: 'Forbidden combustibles goods',
        maxaxleload: 'Maximum axle load',
        totalpermittedweight: 'Maximum weight',
        tunnelrestriction: 'Tunnel restriction code',
        kingpintorearaxle: 'Kingpin to rear axle distance',
        numberofaxles: 'Number of axles',
        length: 'Traffic length',
        delay: 'Delay',
        message: { text: 'Message' }
    }
}
