<template>
    <div id="h-chart"></div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import Moment from 'moment'
import MomentTimeZone from 'moment-timezone'

import Highcharts from 'highcharts'

import XRange from 'highcharts/modules/xrange'
import Accessibility from 'highcharts/modules/accessibility'

import { mapState } from 'vuex'

XRange(Highcharts)
Accessibility(Highcharts)

window.moment = Moment
MomentTimeZone()

export default {
    name: 'ActivityChart',
    props: {
        layers: {
            type: Array,
            required: true
        },
        activities: {
            type: Array,
            required: true
        },
        extremes: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            charts: []
        }
    },
    watch: {
        layers: function () {
            this.draw()
        },
        extremes: function () {
            this.charts.forEach((c) => {
                if (this.extremes) {
                    c.xAxis[0].setExtremes(this.extremes[0], this.extremes[1])
                } else {
                    c.xAxis[0].setExtremes()
                }
            })
        }
    },
    methods: {
        draw() {
            this.charts = []

            let last_tooltip_layer = null

            let speed = []
            let altitude = []
            let consumption = []
            let activities = []

            let start_consumption_point = null
            let last_consumption_point = 0

            let time = null

            for (var layer of this.layers) {
                time = this.moment(layer.feature.properties.date, 'UTC').valueOf()

                speed.push({
                    x: time,
                    y: layer.feature.properties.tacho_speed || layer.feature.properties.speed,
                    l: layer
                })

                altitude.push({
                    x: time,
                    y: layer.feature.properties.altitude,
                    l: layer
                })

                if (start_consumption_point === null && layer.feature.properties.fuel_total)
                    start_consumption_point = layer.feature.properties.fuel_total

                last_consumption_point =
                    layer.feature.properties.fuel_total || last_consumption_point

                consumption.push({
                    x: time,
                    y: last_consumption_point - (start_consumption_point || 0),
                    l: layer
                })

                // if (layer.feature.properties.fuel_total) {
                //     if (current_consumption[0] && (time - current_consumption[0] >= (30 * 60 * 1000))) {
                //         let avg = Math.floor(current_consumption[2].reduce((c,v) => (c + v),0) / 10) / 100

                //         consumption.push({
                //             x: current_consumption[0],
                //             y: avg,
                //             l: layer
                //         })

                //         consumption.push({
                //             x: time,
                //             y: avg,
                //             l: layer
                //         })

                //         current_consumption = [null, null, []]
                //     } else if (current_consumption[1]) {
                //         current_consumption[2].push(
                //             layer.feature.properties.fuel_total - current_consumption[1]
                //         )

                //         current_consumption[1] = layer.feature.properties.fuel_total
                //     } else {
                //         current_consumption[0] = time
                //         current_consumption[1] = layer.feature.properties.fuel_total
                //     }
                // }
            }

            // if (current_consumption[0]) {
            //     let avg = Math.floor(current_consumption[2].reduce((c,v) => (c + v),0) / 10) / 100

            //     consumption.push({
            //         x: current_consumption[0],
            //         y: avg,
            //         l: layer
            //     })

            //     consumption.push({
            //         x: time,
            //         y: avg,
            //         l: layer
            //     })
            // }

            for (var activity of this.activities) {
                let color = ['#87CB7E', '#96CEE9', '#FC9E42', '#E83744']
                let label = [
                    this.__('rest'),
                    this.__('availability'),
                    this.__('work'),
                    this.__('driving')
                ]
                let y_value = [4, 3, 2, 1]

                activities.push({
                    x: this.moment(activity.feature.properties.start_date, 'UTC').valueOf(),
                    x2: this.moment(activity.feature.properties.end_date, 'UTC').valueOf(),
                    y: y_value[activity.feature.properties.activity],
                    l: activity,
                    color: color[activity.feature.properties.activity],
                    text: label[activity.feature.properties.activity]
                })
            }

            let ums = ['l', 'Km/h', 'm s.l.m.', '']
            let g_moment = this.moment

            this.charts.push(
                Highcharts.chart('h-chart', {
                    chart: {
                        zoomType: 'x',
                        backgroundColor: 'transparent',
                        className: 'customized-h-chart',
                        style: {
                            color: 'var(--main-text-color)'
                        }
                    },
                    time: {
                        timezone: 'Europe/Rome'
                    },
                    rangeSelector: {
                        enabled: false
                    },
                    title: {
                        text: null
                    },
                    legend: {
                        enabled: true,
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'middle',
                        itemStyle: { color: 'var(--main-text-color)', opacity: 1 },
                        itemHiddenStyle: { opacity: 0.5 },
                        itemHoverStyle: { color: 'var(--main-text-color--hover)', opacity: 0.5 }
                    },
                    yAxis: [
                        {
                            labels: {
                                enabled: false,
                                style: {
                                    color: 'var(--main-text-color)'
                                }
                            },
                            title: {
                                text: null
                            },
                            height: '75%'
                        },
                        {
                            labels: {
                                enabled: false,
                                style: {
                                    color: 'var(--main-text-color)'
                                }
                            },
                            title: {
                                text: null
                            },
                            height: '75%'
                        },
                        {
                            labels: {
                                enabled: true,
                                format: '{value} l',
                                style: {
                                    color: 'var(--main-text-color)'
                                }
                            },
                            title: {
                                text: null
                            },
                            height: '75%'
                        },
                        {
                            offset: 0,
                            labels: {
                                enabled: false,
                                style: {
                                    color: 'var(--main-text-color)'
                                }
                            },
                            title: {
                                text: null
                            },
                            // categories: [this.__('Riposo'), this.__('Disponibilità'), this.__('Lavoro'), this.__('Guida')],
                            top: '79%',
                            height: '25%',
                            opposite: true
                        }
                    ],
                    xAxis: {
                        type: 'datetime',
                        scrollbar: {
                            enabled: true
                        },
                        labels: {
                            style: {
                                color: 'var(--main-text-color)'
                            }
                        }
                        // min: this.range.start.valueOf()
                    },
                    tooltip: {
                        crosshairs: true,
                        shared: true,
                        formatter: function () {
                            if (last_tooltip_layer) {
                                last_tooltip_layer.closeTooltip
                                    ? last_tooltip_layer.closeTooltip()
                                    : null

                                last_tooltip_layer.setStyle
                                    ? last_tooltip_layer.setStyle(last_tooltip_layer.os)
                                    : null
                            }

                            last_tooltip_layer = this.points[0].point.l

                            last_tooltip_layer.openTooltip ? last_tooltip_layer.openTooltip() : null

                            if (last_tooltip_layer.setStyle) {
                                last_tooltip_layer.os = {
                                    weight: last_tooltip_layer.options.weight,
                                    color: last_tooltip_layer.options.color
                                }

                                last_tooltip_layer.setStyle({
                                    weight: 14,
                                    color: '#35bdfc'
                                })
                            }

                            let html = '<div>'

                            for (var p of this.points) {
                                html +=
                                    '<div style="display: inline-block">' +
                                    p.series.name +
                                    ': ' +
                                    (p.point.text || p.y) +
                                    ' ' +
                                    ums[p.series.index] +
                                    '</div><br>'
                            }

                            html +=
                                '<div style="display: inline-block">' +
                                g_moment(this.x, 'UTC').format('L HH:mm') +
                                '</div>'

                            html += '</div>'

                            return html
                        }
                    },
                    plotOptions: {
                        series: {
                            pointWidth: 10,
                            events: {
                                mouseOut: function () {
                                    if (last_tooltip_layer) {
                                        last_tooltip_layer.closeTooltip
                                            ? last_tooltip_layer.closeTooltip()
                                            : null

                                        last_tooltip_layer.setStyle
                                            ? last_tooltip_layer.setStyle(last_tooltip_layer.os)
                                            : null
                                    }
                                }
                            }
                        }
                    },
                    series: [
                        {
                            turboThreshold: 0,
                            name: this.__('consumption'),
                            um: 'l',
                            yAxis: 2,
                            data: consumption,
                            color: '#7E5CF7'
                        },
                        {
                            turboThreshold: 0,
                            name: this.__('speed'),
                            um: 'Km/h',
                            yAxis: 0,
                            data: speed,
                            color: '#4c586b'
                        },
                        {
                            turboThreshold: 0,
                            name: this.__('altitude'),
                            um: 'm s.l.m.',
                            yAxis: 1,
                            data: altitude,
                            color: '#39C4CE'
                        },
                        {
                            turboThreshold: 0,
                            type: 'xrange',
                            name: this.__('activity'),
                            yAxis: 3,
                            data: activities
                        }
                    ]
                })
            )
        }
    },
    computed: {
        computedYAxis() {
            let chartAxis = this.config?.chart
            let yAxis = []
            let axisStandardTemplate = {
                labels: {
                    enabled: false,
                    style: {
                        color: 'var(--main-text-color)'
                    }
                },
                title: {
                    text: null
                },
                height: '75%'
            }

            Object.entries(chartAxis).forEach(([key, value]) => {
                let tmpAxis = cloneDeep(axisStandardTemplate)

                tmpAxis._name = key

                if (value?.format) tmpAxis.labels.format = value?.format

                if (value?.height) tmpAxis.height = value?.height

                if (value?.opposite) tmpAxis.opposite = value?.opposite

                if (value?.top) tmpAxis.top = value?.top

                if (value?.offset) tmpAxis.offset = value?.offset

                yAxis.push(tmpAxis)
            })

            return yAxis || []
        },
        ...mapState({
            config: (state) => state.geoFleet.config
        })
    }
}
</script>
