<template>
    <side-panel>
        <template #head>
            <div class="a-header justify-content-between pt-4">
                <div class="d-flex flex-column">
                    <h2 class="pt-3">{{ __('plafonds.panel_title') }}</h2>
                    <p class="text-md">
                        {{ __('plafonds.last_update') }}: {{ actualDate | formatDate('UTC') }}
                    </p>
                </div>
                <DriversSelector
                    v-if="lastpoint"
                    :truck-last-point="lastpoint"
                    :value="driver"
                    @change="onDriverChange"
                />
                <div class="close-btn" @click="closePanel('plafond')">
                    <i class="fa-solid fa-xmark"></i>
                </div>
            </div>
        </template>

        <template #body>
            <div class="spinner-wrapper" v-show="loading">
                <Spinner></Spinner>
            </div>

            <div
                class="plafond-grid py-2 px-3"
                :class="[riposiDaCompensare.length > 1 ? 'plafond-grid--multiple-rests' : 'area-g']"
            >
                <div class="area-a">
                    <CurrentActivityCard :driver="driver" />
                </div>
                <div class="area-b">
                    <PlafondInfoWithGougeCard
                        :title="__('plafonds.residual_commitment') + '*'"
                        gouge-icon="fa-user-clock"
                        :gouge-text="impegnoResiduo.residual"
                        :progress="impegnoResiduo.percentage"
                    >
                        <dl class="card-data-list">
                            <template v-for="item in impegnoResiduo.details">
                                <dt :key="'dt_' + item.id">{{ item.label }}</dt>
                                <dd :key="'dd_' + item.id" style="text-align: right">
                                    {{ item.text }}
                                </dd>
                            </template>
                        </dl>
                    </PlafondInfoWithGougeCard>
                </div>
                <div class="area-c">
                    <PlafondInfoWithGougeCard
                        :title="__('plafonds.daily_driving') + '*'"
                        color="#333"
                        gouge-icon="fa-steering-wheel"
                        :gouge-text="guidaGiornaliera.residual"
                        :progress="guidaGiornaliera.percentage"
                    >
                        <dl class="card-data-list">
                            <template v-for="item in guidaGiornaliera.details">
                                <dt :key="'dt_' + item.id">{{ item.label }}</dt>
                                <dd :key="'dd_' + item.id" style="text-align: right">
                                    {{ item.text }}
                                </dd>
                            </template>
                        </dl>
                    </PlafondInfoWithGougeCard>
                </div>
                <div class="area-d">
                    <PlafondInfoWithGougeCard
                        :title="__('plafonds.weekly_guide') + '*'"
                        color="#333"
                        gouge-icon="fa-steering-wheel"
                        :gouge-text="guidaSettimanale.residual"
                        :progress="guidaSettimanale.percentage"
                    >
                        <dl class="card-data-list">
                            <template v-for="item in guidaSettimanale.details">
                                <dt :key="'dt_' + item.id">{{ item.label }}</dt>
                                <dd :key="'dd_' + item.id" style="text-align: right">
                                    {{ item.text }}
                                </dd>
                            </template>
                        </dl>
                    </PlafondInfoWithGougeCard>
                </div>
                <div class="area-e">
                    <PlafondInfoWithGougeCard
                        :title="__('plafonds.biweekly_guide') + '*'"
                        color="#333"
                        gouge-icon="fa-steering-wheel"
                        :gouge-text="guidaBisettimanale.residual"
                        :progress="guidaBisettimanale.percentage"
                    >
                        <dl class="card-data-list">
                            <template v-for="item in guidaBisettimanale.details">
                                <dt :key="'dt_' + item.id">{{ item.label }}</dt>
                                <dd :key="'dd_' + item.id" style="text-align: right">
                                    {{ item.text }}
                                </dd>
                            </template>
                        </dl>
                    </PlafondInfoWithGougeCard>
                </div>
                <div class="area-f">
                    <LastWeekRestCard :rest="ultimoRiposoSettimanale" />
                </div>
                <div class="area-g">
                    <RestsToCompensateCard :rests="riposiDaCompensare" />
                </div>
            </div>
            <p class="px-4" style="font-size: 0.7rem">
                <strong>{{ __('plafonds.footer_message') }}</strong>
            </p>
        </template>
    </side-panel>
</template>

<script>
import { toRefs, ref } from 'vue'
import moment from 'moment'
import { mapState, mapMutations, mapGetters } from 'vuex'

import useDriverPlafond from '../../composables/useDriverPlafond'

import SidePanel from '@/components/panels/SidePanel.vue'
import DriversSelector from './PlafondPanel/DriversSelector.vue'
import CurrentActivityCard from './PlafondPanel/CurrentActivityCard.vue'
import PlafondInfoWithGougeCard from './PlafondPanel/PlafondInfoWithGougeCard.vue'
import LastWeekRestCard from './PlafondPanel/LastWeekRestCard.vue'
import RestsToCompensateCard from './PlafondPanel/RestsToCompensateCard.vue'
import Spinner from 'vue-simple-spinner'

export default {
    name: 'PlafondPanel',
    components: {
        DriversSelector,
        CurrentActivityCard,
        PlafondInfoWithGougeCard,
        LastWeekRestCard,
        RestsToCompensateCard,
        Spinner
    },
    props: {
        truck: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const driver = ref(null)

        const {
            dpv1,
            dpv2,
            dpv3,
            dpv4,
            dpv5,
            dpv6,
            dpv7,
            dpv8,
            dpv9,
            driverActivities,
            actualDate,
            loading,
            getPlafond,
            restType,
            minutesToTime,
            parsingIso8601,
            getIconClass
        } = useDriverPlafond(driver)

        function onDriverChange({ tag, data }) {
            driver.value = data
        }

        return {
            driver,
            actualDate,
            dpv1,
            dpv2,
            dpv3,
            dpv4,
            dpv5,
            dpv6,
            dpv7,
            dpv8,
            dpv9,
            driverActivities,
            loading,
            getPlafond,
            restType,
            minutesToTime,
            parsingIso8601,
            getIconClass,
            onDriverChange
        }
    },
    computed: {
        ...mapState({
            driversPlafondData: (state) => state.geoFleet.driversPlafondData
        }),
        ...mapGetters({
            plafondMeta: 'plafondMeta'
        }),
        lastpoint() {
            return this.truck?.lastpoint || null
        },
        lastUpdate() {
            return this.formatDate()
        },
        impegnoResiduo() {
            let data = this.dpv4 || {}
            let gouge = {}
            gouge.title = data?.title
            gouge.residual = `${data.residualHours}h ${data.residualMinutes}m`
            gouge.percentage = data.percWorkingDay
            gouge.details = [
                {
                    id: 'impegnoResiduo_bonus',
                    label: data?.dpv4Bonus?.title,
                    text: data?.dpv4Bonus?.numBonus || 'n.d.'
                },
                {
                    id: 'impegnoResiduo_residual',
                    label: data?.dpv4ResWD?.title,
                    text: data?.dpv4ResWD?.residualWorkingDay || 'n.d.'
                },
                {
                    id: 'impegnoResiduo_start_impegno',
                    label: data?.dpv4StartWD?.title,
                    text: this.formatDate(data?.dpv4StartWD?.startWorkingDay)
                },
                {
                    id: 'impegnoResiduo_end_impegno',
                    label: data?.dpv4EndWD?.title,
                    text: this.formatDate(data?.dpv4EndWD?.endWorkingDay)
                }
            ]
            return gouge
        },
        guidaGiornaliera() {
            let data = this.dpv5 || {}
            let gouge = {}
            gouge.title = data?.title
            gouge.residual = `${data.plafondDailyHours}h ${data.plafondDailyMinutes}m`
            gouge.percentage = data.percDrivingDay
            // gouge.color = groupColor(data.timeLabel)
            gouge.details = [
                {
                    id: 'guidaGiornaliera_numBonus',
                    label: data?.dpv5Bonus?.title,
                    text: data?.dpv5Bonus?.numBonus
                },
                {
                    id: 'guidaGiornaliera_residualDriveDay',
                    label: data?.dpv5ResDrive?.title,
                    text: data?.dpv5ResDrive?.residualDriveDay
                },
                {
                    id: 'guidaGiornaliera_driveDay',
                    label: data?.dpv5DayDrive?.title,
                    text: data?.dpv5DayDrive?.driveDay
                }
            ]
            return gouge
        },
        guidaSettimanale() {
            let data = this.dpv6 || {}
            let gouge = {}
            gouge.title = data?.title
            gouge.residual = `${data.plafondWeeklyHours}h ${data.plafondWeeklyMinutes}m`
            gouge.percentage = data.percDrivingWeekly
            // gouge.color = groupColor(data.timeLabel)
            gouge.details = [
                {
                    id: 'guidaSettimanale_residualDriveDay',
                    label: data.title,
                    text: `${data.plafondWeeklyHours}h ${data.plafondWeeklyMinutes}m`
                }
            ]

            return gouge
        },
        guidaBisettimanale() {
            let data = this.dpv7 || {}
            let gouge = {}
            gouge.title = data?.title
            gouge.residual = `${data.plafondBiWeeklyHours}h ${data.plafondBiWeeklyMinutes}m`
            gouge.percentage = data.percDrivingBiWeekly
            // gouge.color = groupColor(data.timeLabel)
            gouge.details = [
                {
                    id: 'guidaBisettimanale_from_previuos',
                    label: data.dpv7BiWeeklyDrive?.title,
                    text: `${data.dpv7BiWeeklyDrive?.driveDay}`
                },
                {
                    id: 'guidaBisettimanale_to_next',
                    label: data.dpv7ResDrive?.title,
                    text: `${data.dpv7ResDrive?.residualDriveDay}`
                }
            ]
            return gouge
        },
        ultimoRiposoSettimanale() {
            let { duration, nextDurationHours, nextStartPeriod, periodEnd, periodStart } =
                this.dpv8 || {}

            return {
                duration,
                nextDurationHours: nextDurationHours,
                nextStartPeriod: this.formatDate(nextStartPeriod),
                periodEnd: this.formatDate(periodEnd),
                periodStart: this.formatDate(periodStart)
            }
        },
        riposiDaCompensare() {
            const rests = this.dpv9?.DriverPlafondView9 || []

            return rests.map((r) => {
                let { duration, nextStartPeriod, periodEnd, periodStart, recoveryHours } = r
                return {
                    duration,
                    recoveryHours,
                    nextStartPeriod: this.formatDate(nextStartPeriod),
                    periodEnd: this.formatDate(periodEnd),
                    periodStart: this.formatDate(periodStart)
                }
            })
        }
    },
    watch: {
        driver: {
            immediate: true,
            handler() {
                this.getPlafond({ vueInstance: this })
            }
        }
    },
    created() {
        this.driver = this.plafondMeta?.driver
    },
    methods: {
        ...mapMutations({
            closePanel: 'closePanel'
        }),
        formatDate(date) {
            if (!date) return 'n.d.'
            return this.moment(date).format('l HH:mm')
        }
    }
}
</script>

<style scoped lang="scss">
.a-header {
    flex-wrap: wrap;
}

.text-md {
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}

.badge {
    background-color: gray;

    &-danger {
        background-color: red;
    }
    &-success {
        background-color: green;
    }
    &-warning {
        background-color: orange;
    }
    &-info {
        background-color: #48b0f7;
    }
}

.card.plafond-info-card {
    border-radius: 10px;
    background: #f5f5f5;
    border: none;
    height: 100%;

    ::v-deep(.card-body .card-title) {
        font-size: 1rem;
        font-style: normal;
        font-weight: bold;
        line-height: normal;
    }

    ::v-deep(.card-body .card-data-list) {
        display: grid;
        grid-template-columns: 50% 1fr;
        align-items: center;
        flex: 1;
        width: 100%;
        padding: 10px 0;
        margin: 5px 0;

        dt,
        dd {
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
        }
        dt {
            color: #849ba5;
            font-size: 0.7rem;
        }
        dd {
            font-size: 0.9rem;
        }
    }
}

.rest {
    color: #87cb7e;
}
.available {
    color: #96cee9;
}
.work {
    color: #fc9e42;
}
.drive {
    color: #e83744;
}

.plafond-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
    gap: 20px;
    grid-template-areas:
        'a a a a b b'
        'c c d d e e'
        'f f f g g g'
        'h h h h h h';

    .area-a {
        grid-area: a;
    }
    .area-b {
        grid-area: b;
    }
    .area-c {
        grid-area: c;
    }
    .area-d {
        grid-area: d;
    }
    .area-e {
        grid-area: e;
    }
    .area-f {
        grid-area: f;
    }
    .area-g {
        grid-area: g;
    }
    .area-h {
        grid-area: h;
        .card.plafond-info-card {
            height: auto;
        }
    }
}

.plafond-grid.plafond-grid--multiple-rests {
    grid-template-areas:
        'a a a a b b'
        'c c d d e e'
        'f f f f f f'
        'g g g g g g';

    .area-g {
        grid-area: g;
        .card.plafond-info-card {
            height: auto;
        }
    }
}

@media (max-width: 1200px) {
    .plafond-grid {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
            'a a a a'
            'b b c c'
            'd d e e'
            'f f f f'
            'g g g g';
    }
}

@media (max-width: 900px) {
    .plafond-grid {
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas:
            'a'
            'b'
            'c'
            'd'
            'e'
            'f'
            'g';

        .area-a {
            ::v-deep(.card.plafond-info-card) {
                height: auto;

                .flex-wrap > div {
                    flex: 1 0 100%;
                    margin: 15px 0;
                }
            }

            .card-data-list {
                flex: 1;
            }
        }
    }
}
</style>
