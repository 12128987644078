<template>
    <!-- v-if="false && driverActivities.state" -->
    <div class="plafond-container mb-3" :class="[loading && 'plafond-container--loading']">
        <div class="spinner-wrapper" v-if="loading">
            <Spinner></Spinner>
        </div>
        <template v-else-if="driverActivities.state">
            <div class="p-date">
                {{ __('plafonds.last_update') }}: {{ actualDate | formatDate('UTC') }}
            </div>
            <div class="tiny-wrapper">
                <div class="details-wrap">
                    <h6>{{ __('plafonds.current_activity') }}</h6>
                    <PlafondDriverActivities :value="driverActivities" />
                </div>

                <div class="details-wrap">
                    <h6>{{ __('plafonds.mini_plafond') }}</h6>
                    <div class="d-flex table-element">
                        <div style="min-width: 20px">
                            <i class="fa-solid fa-user-clock"></i>
                        </div>
                        <div style="min-width: 98px; flex-grow: 1">
                            {{ __('residual_commitment') }}<span v-if="driveBonus">*</span>
                        </div>
                        <div class="">{{ residualWorkingDay }}</div>
                    </div>
                    <div class="d-flex table-element">
                        <div style="min-width: 20px">
                            <i class="fa-solid fa-steering-wheel"></i>
                        </div>
                        <div style="min-width: 98px; flex-grow: 1">
                            {{ __('residual_driving') }}<span v-if="restBonus">*</span>
                        </div>
                        <div class="">{{ residualDriveDay }}</div>
                    </div>
                    <div class="d-flex table-element">
                        <div style="min-width: 20px"><i class="fa-solid fa-ticket"></i></div>
                        <div style="min-width: 98px; flex-grow: 1">
                            {{ __('residual_bonuses') }}
                        </div>
                        <div class="d-flex flex-column align-items-end">
                            <div>{{ __('driving') }}: {{ driveBonus }}</div>
                            <div>{{ __('rest') }}: {{ restBonus }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="showTinyHelper" class="tiny-helper">* {{ __('consider_bonuses') }}</div>
                <div class="d-flex justify-content-center mt-2">
                    <!-- TODO: Check permesso visualizzazione plafond, se c'è. -->
                    <!-- v-if="$can('use', 'planner')" -->
                    <golia-button
                        @click="openPlafondPanel(driver)"
                        variant="btn-fill dark-blue mt-3"
                        :label="__('view_plafond_detail')"
                        v-if="$mq !== 'xs' && $mq !== 'sm'"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { ref, toRefs } from 'vue'
import { mapMutations } from 'vuex'
import Spinner from 'vue-simple-spinner'
import useDriverPlafond from '../composables/useDriverPlafond'

export default {
    name: 'TinyPlafond',
    components: {
        Spinner,
        PlafondDriverActivities: () => import('./PlafondDriverActivities')
    },
    props: {
        driver: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const { driver } = toRefs(props)

        const {
            driverActivities,
            actualDate,
            driveBonus,
            residualWorkingDay,
            restBonus,
            residualDriveDay,
            driverPlafond,
            loading,
            getPlafond,
            cleanDriver
        } = useDriverPlafond(driver)

        return {
            actualDate,
            driverActivities,
            residualWorkingDay,
            restBonus,
            residualDriveDay,
            driveBonus,
            driverPlafond,
            loading,
            getPlafond,
            cleanDriver
        }
    },
    data() {
        return {
            date: null
        }
    },
    watch: {
        driver: {
            immediate: true,
            handler() {
                this.getPlafond({ vueInstance: this })
            }
        }
    },
    computed: {
        showTinyHelper() {
            return this.driveBonus || this.restBonus
        }
    },
    methods: {
        ...mapMutations({
            openPlafondPanel: 'openPlafondPanel'
        })
    }
}
</script>

<style scoped>
.spinner-wrapper {
    height: 45px;
    background-color: rgb(255 255 255 / 9%);
}
.plafond-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 0.7rem;
    border-radius: 15px;
}
.plafond-container--loading {
    min-height: 30px;
}
.tiny-wrapper {
    display: flex;
    flex-direction: column;
}
.p-date {
    /* font-size: 0.7rem;
    font-weight: 400; */
    padding-bottom: 8px;

    color: #849ba5;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}

.rest {
    color: #87cb7e;
}
.available {
    color: #96cee9;
}
.work {
    color: #fc9e42;
}
.drive {
    color: #e83744;
}
.details-wrap {
    padding: 8px 5px 5px 5px;
}
.details-wrap i {
    font-size: 0.8rem;
}
.details-wrap h6 {
    color: #2d2d2d;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}
.table-element {
    gap: 5px;
    border-bottom: 1px solid var(--main-gray);
    margin-bottom: 2px;
    padding-bottom: 2px;
}
/* .tiny-txt {
    padding-bottom: 2px;
} */
.tiny-helper {
    font-size: 0.65rem;
    padding-left: 5px;
    padding-top: 5px;
}
</style>
