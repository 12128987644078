import Vue, { unref, computed, ref } from 'vue'
import { parse } from 'tinyduration'
import get from 'lodash/get'
import store from '../store'

/**
 * Recupero ed acesso al plafond di un driver
 */
export default function useDriverPlafond(driver) {
    const loading = ref(false)

    //Plafond del'autista corrente.
    const driverPlafond = computed(() => {
        const plafonds = store.getters['getDriversPlafondData']
        const res = get(plafonds, `${unref(driver).id}.data`, null)
        return res
    })

    const dpv1 = computed(() => {
        return get(driverPlafond.value, 'view.dpv1')
    })
    const dpv2 = computed(() => {
        return get(driverPlafond.value, 'view.dpv2')
    })
    const dpv3 = computed(() => {
        return get(driverPlafond.value, 'view.dpv3')
    })
    const dpv4 = computed(() => {
        return get(driverPlafond.value, 'view.dpv4')
    })
    const dpv5 = computed(() => {
        return get(driverPlafond.value, 'view.dpv5')
    })
    const dpv6 = computed(() => {
        return get(driverPlafond.value, 'view.dpv6')
    })
    const dpv7 = computed(() => {
        return get(driverPlafond.value, 'view.dpv7')
    })
    const dpv8 = computed(() => {
        return get(driverPlafond.value, 'view.dpv8')
    })
    const dpv9 = computed(() => {
        return get(driverPlafond.value, 'view.dpv9')
    })
    const actualDate = computed(() => {
        return get(driverPlafond.value, 'plafond.actualDate', null)
    })
    const dailyPlafondDrive = computed(() => {
        return get(driverPlafond.value, 'plafond.dailyPlafondDrive', '')
    })
    const detectionState = computed(() => {
        return get(driverPlafond.value, 'plafond.detectionState', null)
    })
    const driveDay = computed(() => {
        return get(driverPlafond.value, 'view.dpv5.dpv5DayDrive.driveDay', null)
    })
    const drivePlafond = computed(() => {
        return get(driverPlafond.value, 'plafond.drivePlafond', null)
    })
    const driveTypeNextRest = computed(() => {
        return get(driverPlafond.value, 'plafond.driveTypeNextRest', null)
    })
    const driveBonus = computed(() => {
        return get(driverPlafond.value, 'plafond.driveBonus')
    })
    const residualWorkingDay = computed(() => {
        return get(driverPlafond.value, 'view.dpv4.dpv4ResWD.residualWorkingDay')
    })
    const restBonus = computed(() => {
        return get(driverPlafond.value, 'plafond.restBonus')
    })
    const residualDriveDay = computed(() => {
        return get(driverPlafond.value, 'view.dpv5.dpv5ResDrive.residualDriveDay')
    })
    const restDriveStart = computed(() => {
        return get(driverPlafond.value, 'plafond.restDriveStart', null)
    })

    const isBlock = computed(() => {
        const parse_span = parse(dailyPlafondDrive.value)
        return !parse_span.days && !parse_span.hours && !parse_span.minutes
    })

    const driverActivities = computed(() => {
        if (!driverPlafond.value) return {}

        if (detectionState.value == 'DRIVE') {
            return {
                state: isBlock.value ? 'BLOCK' : 'DRIVE',
                currentText: isBlock.value ? Vue.$__('Fermarsi') : driveDay.value,
                arrowText: parsingIso8601(drivePlafond.value),
                nextText: Vue.$__(restType(driveTypeNextRest.value)),
                nextState: 'REST'
            }
        } else if (detectionState.value == 'REST') {
            return {
                state: 'REST',
                currentText: actualDate.value
                    ? minutesToTime(
                          Vue.moment(actualDate.value, 'UTC').diff(
                              Vue.moment(dpv1.value.dt, 'UTC'),
                              'minutes'
                          )
                      )
                    : null,
                arrowText: restDriveStart.value
                    ? minutesToTime(
                          Vue.moment(restDriveStart.value, 'UTC').diff(
                              Vue.moment(actualDate.value, 'UTC'),
                              'minutes'
                          )
                      )
                    : null,
                nextText: parsingIso8601(dailyPlafondDrive.value),
                nextState: isBlock.value ? 'BLOCK' : 'DRIVE'
            }
        } else {
            return {
                state: detectionState.value,
                currentText: actualDate.value
                    ? minutesToTime(
                          Vue.moment(actualDate.value, 'UTC').diff(
                              Vue.moment(dpv1.value.dt, 'UTC'),
                              'minutes'
                          )
                      )
                    : null,
                arrowText: null,
                nextText: parsingIso8601(dailyPlafondDrive.value),
                nextState: isBlock.value ? 'BLOCK' : 'DRIVE'
            }
        }
    })

    function restType(type) {
        switch (type) {
            case 'SESSION':
                return '45m'
            case 'DAILY':
                return 'Giornaliera'
            case 'WEEKLY':
                return 'Settimanale'
            default:
                return 'Rest'
        }
    }

    function minutesToTime(minutes) {
        let hours = Math.floor(minutes / 60)

        let string = ''

        if (hours) string += hours + 'h '

        string += (minutes % 60) + 'm'

        return string
    }

    function parsingIso8601(duration) {
        let parsed = parse(duration)

        let hours = parsed.hours

        if (parsed.days) hours = hours + parsed.days * 24

        let string = ''

        if (hours) string += hours + 'h '

        string += (parsed.minutes || 0) + 'm'

        return string
    }

    function getIconClass(state) {
        if (state == 'DRIVE') return ['fa-solid', 'fa-steering-wheel', 'drive']
        if (state == 'WORK') return ['fak', 'fa-work', 'work']
        if (state == 'AVAILABILITY') return ['fak', 'fa-availability', 'available']
        if (state == 'BLOCK') return ['fak', 'fa-bar-steering', 'drive']

        return ['fa-solid', 'fa-bed', 'rest']
    }

    async function getPlafond(opts) {
        const vueInstance = opts.vueInstance

        try {
            loading.value = true
            if(!unref(driver)) return
            
            await store.dispatch('loadDriverPlafondData', {
                vueInstance,
                driverId: unref(driver)?.id
            })
        } finally {
            loading.value = false
        }
    }

    function cleanDriver() {
        store.commit('removeDriverFromDriversPlafondData', unref(driver).id)
    }

    return {
        driverPlafond,
        actualDate,
        dpv1,
        dpv2,
        dpv3,
        dpv4,
        dpv5,
        dpv6,
        dpv7,
        dpv8,
        dpv9,
        dailyPlafondDrive,
        detectionState,
        driveDay,
        drivePlafond,
        driveTypeNextRest,
        driveBonus,
        residualWorkingDay,
        restBonus,
        residualDriveDay,
        isBlock,
        driverActivities,
        loading,
        getPlafond,
        restType,
        minutesToTime,
        parsingIso8601,
        getIconClass,
        cleanDriver
    }
}
